import DOMPurify from "dompurify";
import { ReportedLineItemValue } from "../services/income_statement/types";
import { ReportSessionItemStyles, ReportingPeriods, Units, DataFormatTypes } from "../types";
import { getNormalizedReportedMonthsSet, getNormalizedReportedQuartersSet } from "./monthNames";

/**
 * Transforms reported financial data into a standardized format for display.
 * @returns {object} - The transformed financial data in a standardized format.
 */

export const formatValue = (value: number | undefined | null, symbol?: DataFormatTypes): string => {
  symbol = symbol ? symbol : DataFormatTypes.none;

  if (!value && value !== 0) {
    return "-";
  }
  const isNegative = value < 0;
  let formattedValue = Math.abs(value).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  formattedValue = isNegative ? `(${formattedValue}${symbol})` : `${formattedValue}${symbol}`;

  return formattedValue;
};

export async function convertTableValues(
  value: number,
  unit: Units,
  enableConversions?: boolean,
  style?: string | null
): Promise<number> {
  if (!enableConversions) return value;

  if (style && (style === ReportSessionItemStyles.MARGIN || style === ReportSessionItemStyles.RATIO)) {
    return value;
  }

  switch (unit) {
    case Units.MILLIONS:
      return value / 1000000;
    case Units.THOUSANDS:
      return value / 1000;
    default:
      return value;
  }
}

export async function normalizeTimeSeries(
  values: any[] | undefined,
  companyId: number,
  universal_line_item: string,
  financialYear: number,
  companyPeriod: ReportingPeriods,
  reportingPeriod: ReportingPeriods,
  unit: Units,
  style?: string | null,
  enableConversions?: boolean,
  financialYearEnd?: string
): Promise<any[]> {
  const dateObject = financialYearEnd ? new Date(financialYearEnd) : undefined;
  // Extract the month from financialYearEnd
  const endPeriod = dateObject ? dateObject.getMonth() + 1 : undefined;
  const valuesMap = new Map<number, ReportedLineItemValue>();
  const reportedMonthsSet: Set<number> = new Set();
  const reportedQuartersSet: Set<number> = new Set();
  let columns: number;

  switch (reportingPeriod) {
    case ReportingPeriods.Monthly:
      columns = 12;
      break;
    case ReportingPeriods.Quarterly:
      columns = 4;
      break;
    case ReportingPeriods.YTD:
    case ReportingPeriods.LTM:
      if (companyPeriod === ReportingPeriods.Monthly) columns = 12;
      else columns = 4;
      break;
  }

  if (columns !== 12) {
    if (values !== undefined)
      for await (const value of values) {
        value.value = value.value === null ? null : await convertTableValues(value.value, unit, enableConversions, style);
        valuesMap.set(value.reported_quarter, value);
        reportedQuartersSet.add(value.reported_quarter);
      }
  } else {
    if (values !== undefined)
      for await (const value of values) {
        value.value = value.value === null ? null : await convertTableValues(value.value, unit, enableConversions, style);
        valuesMap.set(value.reported_month, value);
        reportedMonthsSet.add(value.reported_month);
      }
  }

  const normalizedReportedMonths = await getNormalizedReportedMonthsSet(reportedMonthsSet, endPeriod);

  const normalizedReportedQuarters = await getNormalizedReportedQuartersSet(reportedQuartersSet);

  const transformedValues = [];
  if (values && columns === 12) {
    // iterate over normalizedReportedMonths
    for (const month of normalizedReportedMonths) {
      // Check if month exists in valuesMap i.e. in API data
      if (!valuesMap.has(month)) {
        // If month does not exist, add a placeholder value
        const placeholderValue: any = {
          id: month,
          company_id: companyId,
          universal_line_item_id: universal_line_item,
          value: null,
          reported_date: null,
          reported_month: month,
          reported_quarter: Math.ceil(month / 3),
          reported_year: financialYear,
          financial_year: financialYear,
          file_upload_uuid: null,
          file_upload_date: null,
          file_upload_name: null,
          file_upload_uri: null,
          processing_date: null,
          insert_timestamp: null,
        };
        transformedValues.push(placeholderValue);
      } else {
        // If month exists, add the value from valuesMap to transformedValues
        transformedValues.push(valuesMap.get(month));
      }
    }
  } else if (values && columns === 4) {
    for (const quarter of normalizedReportedQuarters) {
      if (!valuesMap.has(quarter)) {
        // If quarter does not exist, add a placeholder value
        const placeholderValue: any = {
          id: quarter,
          company_id: companyId,
          universal_line_item_id: universal_line_item,
          value: null,
          reported_date: null,
          reported_month: quarter * 3,
          reported_quarter: quarter,
          reported_year: financialYear,
          financial_year: financialYear,
          file_upload_uuid: null,
          file_upload_date: null,
          file_upload_name: null,
          file_upload_uri: null,
          processing_date: null,
          insert_timestamp: null,
        };
        transformedValues.push(placeholderValue);
      } else {
        // If quarter exists, add the value from valuesMap to transformedValues
        transformedValues.push(valuesMap.get(quarter));
      }
    }
  }

  return transformedValues;
}

export async function areArraysEqual<T>(array1: T[], array2: T[]) {
  // Check length of both arrays is same or not
  if (!Array.isArray(array1)) throw Error("First argument must be an Array");
  if (!Array.isArray(array2)) throw Error("Second argument must be an Array");
  if (array1.length !== array2.length) return false;

  const sortedArray1 = array1.slice().sort();
  const sortedArray2 = array2.slice().sort();

  const areEquals = sortedArray1.every((value, index) => value === sortedArray2[index]);
  return areEquals;
}

//Enforces sanitization policy for DOMPurify that is used for all inputs
export function sanitizeText(input: any): string {
  const sanitizeConfig = {
    ALLOWED_TAGS: ["p", "strong", "em", "u", "s", "h1", "ol", "ul", "li"],
    ALLOWED_ATTR: ["style", "class"],
  };

  const purifier = DOMPurify.sanitize(input, sanitizeConfig);

  return purifier;
}
// Removes html from text - rich text
export function removeHtml(input: any): string {
  const sanitizeConfig = {
    ALLOWED_TAGS: ["#text"],
  };

  const purifier = DOMPurify.sanitize(input, sanitizeConfig);

  return purifier;
}

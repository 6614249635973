import { Stack, Typography } from "@mui/material";
import { IconProps } from "phosphor-react";
import React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import {
  DealDetailItem,
  StyledSecondaryButton,
  StyledTertiaryButton,
  StyledTextField,
  StyledDateField,
  DateContainer,
  LoaderContainer,
} from "./styles";
import { PencilSimple, Check, X } from "phosphor-react";
import { updateDealDetails } from "../../services/deals";
import { LoadingBar } from "@alterdomus-analytics/dna-ui";
import { formatDate } from "../../utils/formatDate";
import { setCompanyField } from "../../redux/reducers/companyReducer";
import DOMPurify from "dompurify";

export type DealDetailProps = {
  label: string;
  value: string;
  testKey: string;
  icon?: React.ForwardRefExoticComponent<IconProps & React.RefAttributes<SVGSVGElement>>;
  styleProps?: any;
};

export const DealDetail = ({ label, value, testKey, icon, styleProps }: DealDetailProps) => {
  return (
    <DealDetailItem sx={{ minHeight: "85px" }}>
      <Typography variant="h6" component="div" e2e-test-id={"deal-name-header"}>
        {label}
      </Typography>
      <Stack direction={"row"} mt={2}>
        {icon && React.createElement(icon, { size: 18, color: "#586463", style: { marginRight: ".5rem" } })}
        <Typography
          title={value}
          variant="body1"
          component="div"
          e2e-test-id={`deal-${testKey}-value`}
          sx={{ styleProps, maxWidth: "197px", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}
        >
          {value}
        </Typography>
      </Stack>
    </DealDetailItem>
  );
};

export interface EditDealDetailProps extends DealDetailProps {
  valueType?: number;
  companyId: string;
}

export enum DealDetailValueTypes {
  default = 1,
  date,
}

type UpdateError = {
  message: string;
  blocksRetry?: boolean;
};

export const EditableDealDetail = ({ label, value, testKey, icon, styleProps, valueType, companyId }: EditDealDetailProps) => {
  const [editingDealDetails, setEditingDealDetails] = useState<boolean>(false);
  const [readValue, setReadValue] = useState<string>(value);
  const [editedValue, setEditedValue] = useState<string>(value);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<UpdateError[]>([]);
  const dispatch = useDispatch();
  const fieldUpdateMap: Record<string, string> = {
    "Internal Rating": "internal_rating",
    "Deal Team": "deal_team",
    "Co-investor": "co_investors",
    "Transaction Date": "transaction_date",
  };
  valueType = valueType ? valueType : DealDetailValueTypes.default;

  //FIXME: Move all validation related code to a separate validation.ts file in the same folder
  function validateRequest(updateField: string, updateValue: string): UpdateError[] {
    // blocking errors must be addressed before the UI will allow a retry of the request:
    const blockingErrors = errors.filter((s) => s.blocksRetry);
    const messages = blockingErrors ? blockingErrors : [];
    const validFields = Object.keys(fieldUpdateMap);

    if (!validFields.includes(updateField)) {
      messages.push({
        message: `Invalid field update requested: "${updateField}".\nValid options are: ${validFields.join(",")}`,
      });
    }

    let parsedValue = null;
    switch (updateField) {
      case "Internal Rating":
        parsedValue = parseFloat(updateValue);
        if (updateValue !== "" && updateValue !== null && updateValue !== undefined && isNaN(parsedValue)) {
          messages.push({ message: "Value must be a number" });
        }
        break;
      case "Transaction Date":
        parsedValue = new Date(updateValue);
        if (
          updateValue !== "" &&
          updateValue !== null &&
          updateValue !== undefined &&
          parsedValue.toString() === "Invalid Date"
        ) {
          messages.push({ message: "Value must be a valid date" });
        }
        break;
      default:
        break;
    }

    return messages;
  }

  async function handleUpdateDealDetails(field: string, value: string) {
    setIsLoading(true);

    let response;
    const validationErrors = validateRequest(field, value);
    if (!validationErrors.length) {
      response = await updateDealDetails(companyId, field, value);
      response.errors = response.messages.map((m: UpdateError) => {
        return { message: m };
      });
    } else {
      response = {
        success: false,
        errors: validationErrors,
      };
    }

    if (response.success) {
      setReadValue(value);
      setEditingDealDetails(false);
      setErrors([]);
      dispatch(
        setCompanyField({ [fieldUpdateMap[field]]: fieldUpdateMap[field] === "co_investors" ? value.split("\n") : value })
      );
    } else {
      setErrors(response.errors);
    }

    setIsLoading(false);
  }

  //FIXME: Move all validation related code to a separate validation.ts file in the same folder
  function validateValueLength(value: string) {
    const maxLength = getFieldMaxLength();
    const error = lengthError();
    if (value.length > maxLength) {
      setErrors([error]);
    } else {
      setErrors(errors.filter((e) => e.message !== error.message));
    }
  }

  function lengthError() {
    return { message: `Value must be ${getFieldMaxLength()} characters or less`, blocksRetry: true };
  }

  function getFieldMaxLength(): number {
    let maxLength = 500;
    switch (label) {
      case "Deal Team":
        maxLength = 50;
        break;
      case "Internal Rating":
        maxLength = 4;
        break;
      default:
        break;
    }
    return maxLength;
  }

  function handleDealDetailOnChange(newValue: string) {
    newValue = DOMPurify.sanitize(newValue);
    validateValueLength(newValue);
    if (valueType === DealDetailValueTypes.date) {
      newValue = newValue === "" ? "" : formatDate(newValue);
      setEditedValue(newValue);
    } else {
      setEditedValue(newValue.toString());
    }
  }

  return (
    <DealDetailItem sx={{ minHeight: "93px" }}>
      {/* Header */}
      <Stack direction={"row"} alignItems="stretch" justifyContent="space-between" mt={0} style={{ flexGrow: 1 }}>
        <Typography variant="h6" component="div" e2e-test-id={"deal-name-header"}>
          {label}
        </Typography>
        {!editingDealDetails && (
          <StyledTertiaryButton
            label="Edit"
            variantType="tertiary"
            startIcon={<PencilSimple size={14} color="#1a50dd" />}
            onClick={() => {
              setEditingDealDetails(true);
            }}
          />
        )}
      </Stack>

      {/* Content Line */}
      {isLoading && (
        <LoaderContainer>
          <LoadingBar progress={30} />
        </LoaderContainer>
      )}
      {!isLoading && (
        <Stack direction={"row"} justifyContent="space-between" alignItems="flex-start" mt={1}>
          <Stack direction={"row"} mt={0} style={{ flex: "0 0 75%" }}>
            {icon && React.createElement(icon, { size: 18, color: "#586463", style: { marginRight: ".5rem" } })}
            {!editingDealDetails && (
              <Typography
                title={readValue}
                variant="body1"
                component="div"
                e2e-test-id={`deal-${testKey}-value`}
                sx={{ styleProps, maxWidth: "197px", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}
              >
                {readValue}
              </Typography>
            )}
            {editingDealDetails && valueType === DealDetailValueTypes.default && (
              <div>
                <StyledTextField
                  id="edit-deal-details-input"
                  placeholder={`Enter ${label}`}
                  defaultValue={editedValue}
                  onChange={(event) => {
                    handleDealDetailOnChange(event.target.value);
                  }}
                  //FIXME: add a prop to the StyledTextField copnonent so the color can be set there without an in-line style
                  style={errors.length ? { borderColor: "#9F372F" } : { borderColor: "#1a50dd" }}
                  inputProps={{ maxLength: getFieldMaxLength() + 1 }}
                />
                {/** FIXME: Change the following <p> tag to a styled component */}
                <p style={{ color: "#9F372F", margin: 0, fontSize: "12px" }}>{errors.map((e) => e.message).join("\n")}</p>
              </div>
            )}
            {editingDealDetails && valueType === DealDetailValueTypes.date && (
              <DateContainer
                sx={
                  errors.length
                    ? { "& > div > .MuiBox-root > .MuiFormControl-root": { borderColor: "#9F372F" } }
                    : { "& > div > .MuiBox-root > .MuiFormControl-root": { borderColor: "#1a50dd" } }
                }
              >
                <StyledDateField
                  label=""
                  value={editedValue}
                  inputFormat="MMM DD, YYYY"
                  onChange={(e: any) => {
                    e = e === null ? { $d: "" } : e;
                    e && handleDealDetailOnChange(e.$d);
                  }}
                />
                {/** FIXME: Change the following <p> tag to a styled component */}
                <p style={{ color: "#9F372F", margin: 0, fontSize: "12px" }}>{errors.map((e) => e.message).join("\n")}</p>
              </DateContainer>
            )}
          </Stack>

          {editingDealDetails && (
            <Stack direction={"row"} mt={0} justifyContent="space-between" sx={{ flexBasis: "80px" }}>
              <StyledSecondaryButton
                label=""
                variantType="secondary"
                startIcon={<Check size={14} color="#1a50dd" />}
                sx={{ "& .MuiButton-startIcon": { margin: "0px" } }}
                onClick={() => {
                  handleUpdateDealDetails(label, editedValue);
                }}
              />
              <StyledSecondaryButton
                label=""
                size="small"
                variantType="secondary"
                startIcon={<X size={14} style={{ margin: "0 !important" }} color="#1a50dd" />}
                sx={{ "& .MuiButton-startIcon": { margin: "0px" } }}
                onClick={() => {
                  setEditingDealDetails(false);
                  setErrors([]);
                  setEditedValue(readValue);
                }}
              />
            </Stack>
          )}
        </Stack>
      )}
    </DealDetailItem>
  );
};

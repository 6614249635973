import { Header, IOption } from "@alterdomus-analytics/dna-ui";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { AnalyzerTabs } from "../../components/AnalyzerTabs/AnalyzerTabs";
import { AppHeader } from "../../components/AppHeader";
import { GlobalFilters } from "../../components/GlobalFilters";
import { Loader } from "../../components/Loader";
import { MultiSelectItemType } from "../../components/MultiSelect";
import { OverviewTable } from "../../components/OverviewTable";
import { PeriodSelector } from "../../components/PeriodSelector";
import { SideBarMenu } from "../../components/SideBarMenu";
import { setSelectedPortfolio } from "../../redux/reducers/portfolioReducer";
import { getDropdownOptions } from "../../services/deals";
import { getGlobalFilters } from "../../services/portfolio_analyzer";
import {
  AnalyzerRow,
  AnalyzerTables,
  Country,
  FinancialStatements,
  Fund,
  IRow,
  Portfolio,
  ReportingPeriods,
  RevEbitdaViews,
  Sector,
} from "../../types";
import { PerformanceFilterOption } from "../../types/";
import { Container, ContainerGrid, HeaderContainer, SearchBar, StyledStack, StyledWrapper, Wrapper } from "./styles";
import { Stack } from "@mui/material";
import { ExportToFile } from "../../components/ExportToFile";
import { ExportToFileType } from "../../components/ExportToFile/types.d";
import { format } from "date-fns";

export const AnalyzerPage = () => {
  const dispatch = useDispatch();
  const viewToShow = RevEbitdaViews.PERCENTAGE;
  const [loaded, setLoaded] = useState(false);
  // global filters state vars
  //TODO: move this state variables to GlobalFilters component and fetch values there
  const [portfolios, setPortfolios] = useState<Portfolio[]>([]);
  const [funds, setFunds] = useState<Fund[]>([]);
  const [sectors, setSectors] = useState<Sector[]>([]);
  const [countries, setCountries] = useState<Country[]>([]);
  // selected filters state vars
  const [portfolioSelected, setPortfolioSelected] = useState<Portfolio | null>(null);
  const [fundsSelected, setFundsSelected] = useState<MultiSelectItemType[]>([]);
  const [sectorsSelected, setSectorsSelected] = useState<MultiSelectItemType[]>([]);
  const [countriesSelected, setCountriesSelected] = useState<MultiSelectItemType[]>([]);
  const [parValues, setParValues] = useState<PerformanceFilterOption[]>([]);
  const [leverages, setLeverages] = useState<PerformanceFilterOption[]>([]);
  const [revenuesVsBudget, setRevenuesVsBudget] = useState<PerformanceFilterOption[]>([]);
  const [revenuesVsLastYear, setRevenuesVsLastYear] = useState<PerformanceFilterOption[]>([]);
  const [parValuesSelected, setParValuesSelected] = useState<(number | null)[]>([null, null]);
  const [leveragesSelected, setLeveragesSelected] = useState<(number | null)[]>([null, null]);
  const [revenuesVsBudgetSelected, setRevenuesVsBudgetSelected] = useState<(number | null)[]>([null, null]);
  const [revenuesVsLastYearSelected, setRevenuesVsLastYearSelected] = useState<(number | null)[]>([null, null]);
  // search bar state vars
  const [searchDropdownOptions, setSearchDropdownOptions] = useState<IOption[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [searchTermSelected, setSearchTermSelected] = useState<string>("");
  const [searchTimerId, setSearchTimerId] = useState<number | null>(null);
  const [loadingSearchOptions, setLoadingSearchOptions] = useState<boolean>(false);
  // period selector state vars
  const [selectedPeriod, setSelectedPeriod] = useState<ReportingPeriods>(ReportingPeriods.LTM);
  const [excelExportDetails, setExcelExportDetails] = useState<IRow[] | AnalyzerRow[]>([]);
  const [activeTab, setActiveTab] = useState("");

  const changeSelectedPorfolioInRedux = async (id: string, description: string) => {
    // updating the selected portfolio in Redux to use this
    // info in other child components like OverviewTable below.
    dispatch(
      setSelectedPortfolio({
        data: {
          id,
          description,
        },
      })
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoaded(false);
      const filters = await getGlobalFilters(selectedPeriod);

      const selectedPortfolio = filters?.portfolios[0];
      setPortfolioSelected(selectedPortfolio);
      await changeSelectedPorfolioInRedux(selectedPortfolio.id, selectedPortfolio.portfolio_name);

      setPortfolios(filters.portfolios);
      setFunds(filters.funds);
      setSectors(filters.sectors);
      setCountries(filters.countries);

      setParValues(filters.parValue);
      setLeverages(filters.leverage);
      setRevenuesVsBudget(filters.revenueVsBudget);
      setRevenuesVsLastYear(filters.revenueVsLastYear);

      setLoaded(true);
    };
    if (activeTab === "") {
      setActiveTab(AnalyzerTables.OVERVIEW);
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, selectedPeriod]);

  const filter = async (
    portfolioFiltered?: MultiSelectItemType,
    fundsFiltered?: MultiSelectItemType[],
    sectorsFiltered?: MultiSelectItemType[],
    countriesFiltered?: MultiSelectItemType[],
    parValuesFiltered?: (number | null)[],
    leveragesFiltered?: (number | null)[],
    revenuesVsBudgetFiltered?: (number | null)[],
    revenuesVsLastYearFiltered?: (number | null)[],
    period?: ReportingPeriods
  ) => {
    if (!fundsFiltered && !sectorsFiltered && !countriesFiltered) {
      return;
    }

    // filtering
    setLoaded(false);

    if (portfolioFiltered) {
      // updating selected values for filters
      await changeSelectedPorfolioInRedux(portfolioFiltered.id, portfolioFiltered.description);
    }

    setFundsSelected(fundsFiltered as MultiSelectItemType[]);
    setSectorsSelected(sectorsFiltered as MultiSelectItemType[]);
    setCountriesSelected(countriesFiltered as MultiSelectItemType[]);

    parValuesFiltered = parValuesFiltered ? parValuesFiltered : [null, null];
    setParValuesSelected(parValuesFiltered);

    leveragesFiltered = leveragesFiltered ? leveragesFiltered : [null, null];
    setLeveragesSelected(leveragesFiltered);

    revenuesVsBudgetFiltered = revenuesVsBudgetFiltered ? revenuesVsBudgetFiltered : [null, null];
    setRevenuesVsBudgetSelected(revenuesVsBudgetFiltered);

    revenuesVsLastYearFiltered = revenuesVsLastYearFiltered ? revenuesVsLastYearFiltered : [null, null];
    setRevenuesVsLastYearSelected(revenuesVsLastYearFiltered);

    // FIXME: change the response for getDealsByFilters/EbitdaRevenue/Covenants to return an updated
    // search dropdown list instead of making this extra call after each filter update:
    if (searchTerm) {
      const portfolioId = portfolioSelected ? portfolioSelected.id : "";
      updateSearchOptions(
        portfolioId,
        fundsFiltered,
        sectorsFiltered,
        countriesFiltered,
        parValuesFiltered,
        leveragesFiltered,
        revenuesVsBudgetFiltered,
        revenuesVsBudgetFiltered,
        period,
        searchTerm
      );
    }
    setLoaded(true);
  };

  const headerPortfolioName = portfolioSelected?.portfolio_type === "DL" ? "Direct Lending" : "";

  function onSearchEnter(event: any) {
    if (event?.key === "Enter") {
      setSearchTermSelected(searchTerm);
    }
  }

  function onSearchSelected(value: any) {
    let newValue: IOption;
    if (value === null) {
      newValue = { label: "", value: "", isClearable: true };
      setSearchDropdownOptions([]);
    } else if (typeof value === "string") {
      newValue = { label: value, value: value, isClearable: true };
    } else {
      newValue = value;
    }
    setSearchTerm(newValue.label);
    setSearchTermSelected(newValue.label);
  }

  function onSearchChanged(event: any) {
    searchTimerId && window.clearTimeout(searchTimerId);
    if (event?.type === "change") {
      let search = event.target?.value;
      search = search ? search : "";
      setSearchTerm(search);
      if (search.trim()) {
        const timerId = window.setTimeout(async () => {
          const portfolioId = portfolioSelected ? portfolioSelected.id : "";
          updateSearchOptions(
            portfolioId,
            fundsSelected,
            sectorsSelected,
            countriesSelected,
            parValuesSelected,
            leveragesSelected,
            revenuesVsBudgetSelected,
            revenuesVsLastYearSelected,
            selectedPeriod,
            search
          );
        }, 300);
        setSearchTimerId(timerId);
        return;
      }
    }
  }

  async function updateSearchOptions(
    portfolioId: string,
    funds?: MultiSelectItemType[],
    sectors?: MultiSelectItemType[],
    countries?: MultiSelectItemType[],
    parValues?: (number | null)[],
    leverages?: (number | null)[],
    revenuesVsBudget?: (number | null)[],
    revenuesVsLastYear?: (number | null)[],
    period?: ReportingPeriods,
    search?: string
  ) {
    setLoadingSearchOptions(true);
    const options = await getDropdownOptions(
      portfolioId,
      funds,
      sectors,
      countries,
      parValues,
      leverages,
      revenuesVsBudget,
      revenuesVsLastYear,
      period,
      search
    );
    const existingOptions = searchDropdownOptions.filter((s) => options.find((o) => o.label === s.label));
    const newOptions = options.filter((o) => !existingOptions.find((e) => e.label === o.label));
    setSearchDropdownOptions([...existingOptions, ...newOptions]);
    setLoadingSearchOptions(false);
  }

  const handlePeriodChange = (value: ReportingPeriods) => {
    if (value !== selectedPeriod) {
      setParValues([]);
      setLeverages([]);
      setRevenuesVsBudget([]);
      setRevenuesVsLastYear([]);

      setParValuesSelected([null, null]);
      setLeveragesSelected([null, null]);
      setRevenuesVsBudgetSelected([null, null]);
      setRevenuesVsLastYearSelected([null, null]);
    }
    setSelectedPeriod(value);
  };

  const getExcelExportDetails = (details: IRow[] | AnalyzerRow[]) => {
    setExcelExportDetails(details);
  };

  const handleTabChange = (table: AnalyzerTables) => {
    setActiveTab(table);
    if (table !== AnalyzerTables.REVENUE_EBITDA) setSelectedPeriod(ReportingPeriods.LTM);
  };

  const sectorProps = !sectorsSelected.every((s) => s == null) ? sectorsSelected : sectors;
  const contriesProps = !countriesSelected.every((c) => c == null) ? countriesSelected : countries;
  const fundProps = !fundsSelected.every((f) => f == null) ? fundsSelected : funds;

  return (
    <>
      <AppHeader />
      <StyledWrapper>
        <SideBarMenu />
        <Wrapper>
          <HeaderContainer isSticky={false} sx={{}}>
            <Header title={`Portfolio: ${headerPortfolioName}`} width="100%" />
          </HeaderContainer>
          <ContainerGrid container>
            <Container item xs={12}>
              <Stack direction="row" gap={1}>
                {/* Search Bar */}
                <SearchBar
                  name=""
                  isSearch
                  freeSolo={true}
                  placeholder="Search by portfolio company"
                  isOptionEqualToValue={(option, value) => option.label === value.label}
                  loading={loadingSearchOptions}
                  value={{ label: searchTerm, value: searchTerm, isClearable: true }}
                  options={searchDropdownOptions}
                  disableClearable={false}
                  onChange={(e, value) => onSearchSelected(value)}
                  onInputChange={(e: any) => onSearchChanged(e)}
                  onKeyDown={(e: any) => onSearchEnter(e)}
                />
                <ExportToFile
                  type={ExportToFileType.PortfolioAnalyzer}
                  showButton={true}
                  portfolioAnalyzerTab={activeTab as AnalyzerTables}
                  data={excelExportDetails}
                  fileName={`${portfolioSelected?.portfolio_type}_${activeTab}_${format(new Date(), "yyyymmdd")}.xlsx`}
                  fileType="xlsx"
                  globalFiltersProps={{
                    funds: fundProps,
                    sectors: sectorProps,
                    countries: contriesProps,
                    parValueRange: parValuesSelected,
                    leverageRange: leveragesSelected,
                    revenueVsBudgetRange: revenuesVsBudgetSelected,
                    revenueVsLastYearRange: revenuesVsLastYearSelected,
                  }}
                />
              </Stack>

              <GlobalFilters
                portfolios={portfolios}
                funds={funds}
                sectors={sectors}
                countries={countries}
                parValues={parValues}
                leverages={leverages}
                revenuesVsBudget={revenuesVsBudget}
                revenuesVsLastYear={revenuesVsLastYear}
                selectedFunds={fundsSelected}
                selectedSectors={sectorsSelected}
                selectedCountries={countriesSelected}
                selectedParValues={parValuesSelected}
                selectedLeverages={leveragesSelected}
                selectedRevenuesVsBudget={revenuesVsBudgetSelected}
                selectedRevenuesVsLastYear={revenuesVsLastYearSelected}
                onFilter={(portfolio, funds, sectors, countries, parValues, leverages, revenuesVsBudget, revenuesVsLastYear) =>
                  filter(portfolio, funds, sectors, countries, parValues, leverages, revenuesVsBudget, revenuesVsLastYear)
                }
              />

              <AnalyzerTabs
                labels={["Overview", "Revenue & EBITDA", "Covenants"]}
                content={[
                  !loaded ? (
                    <Loader /> //Shows the loader while filters are loading
                  ) : (
                    portfolioSelected && (
                      <OverviewTable
                        loaded={loaded}
                        tab={AnalyzerTables.OVERVIEW}
                        view={viewToShow}
                        portfolioId={portfolioSelected.id}
                        portfolioType={portfolioSelected.portfolio_type}
                        funds={fundsSelected}
                        sectors={sectorsSelected}
                        countries={countriesSelected}
                        search={searchTermSelected}
                        setTabCtxFn={() => handleTabChange(AnalyzerTables.OVERVIEW)}
                        parValueRange={parValuesSelected}
                        leverageRange={leveragesSelected}
                        revenueVsBudgetRange={revenuesVsBudgetSelected}
                        revenueVsLastYearRange={revenuesVsLastYearSelected}
                        getExcelExportDetails={getExcelExportDetails}
                      />
                    )
                  ),
                  portfolioSelected && (
                    <>
                      <StyledStack direction={"row"} justifyContent={"flex-end"}>
                        <PeriodSelector
                          statement={FinancialStatements.BALANCE_SHEET}
                          reportingCycle={ReportingPeriods.Monthly}
                          onValueChange={handlePeriodChange}
                          value={selectedPeriod}
                          periodHasData={{
                            deafultPeriod: ReportingPeriods.LTM,
                            hasLtm: true,
                            hasMonthly: true,
                            hasQuarterly: true,
                            hasYtd: true,
                          }}
                        />
                        {
                          //   <RadioGroup value={viewToShow} onChange={handleViewChange} name="radio-report-type">
                          //     <StyledStack direction={"row"}>
                          //       <TypesGroupLabel
                          //         value={RevEbitdaViews.PERCENTAGE}
                          //         control={<Radio />}
                          //         label="Percentages"
                          //         e2e-test-id={"view-percentages-radio"}
                          //       />
                          //       <TypesGroupLabel
                          //         value={RevEbitdaViews.AMOUNT}
                          //         control={<Radio />}
                          //         label="Amounts"
                          //         sx={{ marginRight: 0 }}
                          //         e2e-test-id={"view-amounts-radio"}
                          //       />
                          //     </StyledStack>
                          //   </RadioGroup>
                        }
                      </StyledStack>

                      <OverviewTable
                        loaded={loaded}
                        tab={AnalyzerTables.REVENUE_EBITDA}
                        view={viewToShow}
                        portfolioId={portfolioSelected.id}
                        portfolioType={portfolioSelected.portfolio_type}
                        funds={fundsSelected}
                        sectors={sectorsSelected}
                        countries={countriesSelected}
                        search={searchTermSelected}
                        period={selectedPeriod}
                        setTabCtxFn={() => handleTabChange(AnalyzerTables.REVENUE_EBITDA)}
                        parValueRange={parValuesSelected}
                        leverageRange={leveragesSelected}
                        revenueVsBudgetRange={revenuesVsBudgetSelected}
                        revenueVsLastYearRange={revenuesVsLastYearSelected}
                        getExcelExportDetails={getExcelExportDetails}
                      />
                    </>
                  ),
                  portfolioSelected && (
                    <OverviewTable
                      loaded={loaded}
                      tab={AnalyzerTables.COVENANTS}
                      portfolioId={portfolioSelected.id}
                      portfolioType={portfolioSelected.portfolio_type}
                      funds={fundsSelected}
                      sectors={sectorsSelected}
                      countries={countriesSelected}
                      search={searchTermSelected}
                      setTabCtxFn={() => handleTabChange(AnalyzerTables.COVENANTS)}
                      parValueRange={parValuesSelected}
                      leverageRange={leveragesSelected}
                      revenueVsBudgetRange={revenuesVsBudgetSelected}
                      revenueVsLastYearRange={revenuesVsLastYearSelected}
                      getExcelExportDetails={getExcelExportDetails}
                    />
                  ),
                ]}
              />
            </Container>
          </ContainerGrid>
        </Wrapper>
      </StyledWrapper>
    </>
  );
};

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SetCompanyPayload } from "../definitions";
import { Company } from "../../types";

const initialState: Company = {
  deal_name: "",
  company_trade_name: "",
  currency: "",
  country: "",
  sector: "",
  sponsors: [],
  deal_team: "",
  co_investors: [],
  transaction_date: "",
  reporting_cycle: "m",
  reporting_unit: "",
  financial_year_end: "",
  description: "",
  transaction_background: "",
  current_period: "",
  cellid: 0,
  enable_conversions: false,
  internal_rating: 0,
  orgId: "",
  fin_model_uri: "",
  reports: [],
};

const companySlice = createSlice({
  name: "companySlice",
  initialState,
  reducers: {
    setCompany: (state, action: PayloadAction<SetCompanyPayload>) => {
      return {
        ...state,
        deal_name: action.payload.deal_name,
        company_trade_name: action.payload.company_trade_name,
        currency: action.payload.currency,
        country: action.payload.country,
        sector: action.payload.sector,
        sponsors: action.payload.sponsors,
        deal_team: action.payload.deal_team,
        co_investors: action.payload.co_investors,
        transaction_date: action.payload.transaction_date,
        reporting_cycle: action.payload.reporting_cycle,
        reporting_unit: action.payload.reporting_unit,
        financial_year_end: action.payload.financial_year_end,
        description: action.payload.description,
        transaction_background: action.payload.transaction_background,
        current_period: action.payload.current_period,
        cellid: 0,
        enable_conversions: action.payload.enable_conversions,
        internal_rating: action.payload.internal_rating,
        management_commentary: action.payload.management_commentary,
        fin_model_uri: action.payload.fin_model_uri,
        reports: action.payload.reports,
      };
    },
    setCompanyField: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export default companySlice.reducer;
export const { setCompany, setCompanyField } = companySlice.actions;

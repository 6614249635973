import {
  GridAlignment,
  GridCellParams,
  GridColDef,
  GridColumnGroupHeaderParams,
  GridColumnGroupingModel,
  GridColumnHeaderParams,
  GridRenderCellParams,
} from "@mui/x-data-grid";
import { ReportLineItem, ReportSection, ReportingPeriods, StatementType, Units } from "../../types";
import { PdfFiles } from "./PdfFiles";
import { ReportedDataCell } from "./ReportedCell";
import { StandardDataCell } from "./StandardCell";
import { getFiles } from "../../utils/files";
import { HighlightedCell } from ".";
import { getNumberOfColumns, getPeriodHeaderName } from "../../utils/monthNames";

const getColumnGroupingModel = (financialYear: number) => {
  const columnGroupingModel: GridColumnGroupingModel = [
    {
      groupId: `FY`,
      headerAlign: "center",
      headerClassName: "group-header",
      renderHeaderGroup: (params: GridColumnGroupHeaderParams) => {
        return <span e2e-test-id={"statement-FY-value"}>FY {financialYear}</span>;
      },
      children: [
        { field: "1" },
        { field: "2" },
        { field: "3" },
        { field: "4" },
        { field: "5" },
        { field: "6" },
        { field: "7" },
        { field: "8" },
        { field: "9" },
        { field: "10" },
        { field: "11" },
        { field: "12" },
      ],
    },
  ];
  return columnGroupingModel;
};

export const getColumns = (
  mode: StatementType,
  period: ReportingPeriods,
  reportingCycle: ReportingPeriods,
  currency: string,
  unit: Units,
  statementName: string,
  handleOpenTraceability: (data_point: string, uuid: string, month: number) => void,
  data: ReportSection[],
  highlightItem?: HighlightedCell
) => {
  const columns: GridColDef[] = [
    {
      field: "name",
      renderHeader: (params: GridColumnHeaderParams) => {
        return (
          <div e2e-test-id={"statement-currency"}>
            {currency} <span e2e-test-id={"statement-unit"}>{unit}</span>
          </div>
        );
      },
      cellClassName: "cvx-datapoint--cell",
      headerClassName: "header-title--italics",
      width: 320,
      sortable: false,
    },
  ];

  const numOfMonths = getNumberOfColumns(period, reportingCycle);
  const reportedPeriodSet: Set<number> = new Set();
  const columnNames: string[] = [];
  if (reportingCycle === ReportingPeriods.Monthly) {
    // Iterate over the normalized data to gather unique reported months into a reportedPeriodSet array
    data.forEach((item: ReportLineItem) => {
      if (item.values) {
        item.values.forEach((value) => {
          if (value.reported_month) {
            reportedPeriodSet.add(value.reported_month);
          }
        });
      }
    });
  } else {
    // Iterate over the normalized data to gather unique reported quarters into a reportedPeriodSet array
    data.forEach((item: ReportLineItem) => {
      if (item.values) {
        item.values.forEach((value) => {
          if (value.reported_quarter) {
            reportedPeriodSet.add(value.reported_quarter);
          }
        });
      }
    });
  }

  // Get the period header name for each reported period
  reportedPeriodSet.forEach((reportedPeriod) => {
    const periodHeaderName = getPeriodHeaderName(reportedPeriod, period, reportingCycle);
    columnNames.push(periodHeaderName);
  });

  for (let i = 0; i < numOfMonths; i++) {
    const index = i;
    const fieldName = (i + 1).toString();
    const columnName = columnNames[i];
    const files = getFiles(data, i);

    const columnHeader = (params: GridColumnHeaderParams) => {
      return <PdfFiles columnName={columnName} index={index} files={files} />;
    };

    const cellContent = (params: GridRenderCellParams) => {
      const value = params.row.values[index];
      if (!value) {
        // return (
        //   // Dummy data cell for undefined value object - prints "-"
        //   <ReportedDataCell
        //     data_point={params.row.name}
        //     month={normalizedReportedMonths[i]}
        //     value={null}
        //     style={params.row.style}
        //     statement={statementName}
        //   />
        // )
        return null;
      }

      if (mode === StatementType.STANDARDIZED) {
        return (
          <StandardDataCell
            e2e-test-id={`statement-cell-${index}`}
            companyId={value.company_id}
            statement={value?.formula_statement ? value?.formula_statement : statementName}
            period={period}
            companyReportingCycle={reportingCycle}
            data_point={params.row.name}
            uuid={value.universal_line_item_id}
            value={value.value}
            month={value.reported_month}
            style={params.row.style}
            fiscalYear={value.financial_year}
            formula={value?.formula_string}
            is_mapping={value?.formula_is_mapping}
            handleOpenTraceability={handleOpenTraceability}
          />
        );
      } else {
        if (
          highlightItem &&
          value.reported_month === highlightItem.month &&
          value.universal_line_item_id === highlightItem.target_uuid
        )
          value.style = "highlighted-cell";

        return (
          <ReportedDataCell
            companyId={value.company_id}
            data_point={params.row.name}
            month={value.reported_month}
            value={value.value}
            style={params.row.style}
            statement={statementName}
            fileUri={value.file_upload_uri}
            fileName={value.file_upload_name}
            mode={mode}
            leftCoordinate={value.pdf_left_coordinate}
            rightCoordinate={value.pdf_right_coordinate}
            topCoordinate={value.pdf_top_coordinate}
            bottomCoordinate={value.pdf_bottom_coordinate}
            pageNumber={value.pdf_page_number}
            uuid={value.universal_line_item_id}
          />
        );
      }
    };

    const cellClass = (params: GridCellParams) => {
      const style = params.row.values[index]?.style;

      if (style === "highlighted-cell") {
        return "highlighted-cell";
      }

      return "cvx-right--cell";
    };

    const column = {
      field: fieldName,
      renderHeader: columnHeader,
      headerAlign: "center" as GridAlignment,
      cellClassName: cellClass,
      align: "right" as GridAlignment,
      flex: 1,
      minWidth: 82,
      sortable: false,
      renderCell: cellContent,
    };

    columns.push(column);
  }

  return columns;
};

export const columnGroupingModel = (headerName: number) => getColumnGroupingModel(headerName);

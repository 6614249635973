import { LinearProgress } from "@alterdomus-analytics/dna-ui";
import { Box, Divider, Radio, RadioGroup, SelectChangeEvent } from "@mui/material";
import { SetStateAction, useEffect, useState } from "react";
import {
  DebtCovenantsStatements,
  FilterOptions,
  FinancialStatements,
  PeriodHasData,
  ReportLineItem,
  ReportingPeriods,
  StatementType,
  Units,
} from "../../types";
import { formatPeriodDate } from "../../utils/formatDate";
import { getReportingPeriodAbbreviationLegacy } from "../../utils/reporting-periods-helper";
import { Chart, ChartType } from "../Chart";
import { CurrentPeriodTable } from "../CurrentPeriodTable";
import { ExportToFile } from "../ExportToFile";
import { ExportToFileType } from "../ExportToFile/types.d";
import { ExportToFileWrapper } from "../FinancialStatement/styles";
import { PeriodSelector } from "../PeriodSelector";
import BasicSelect from "../Select";
import { StatementTable } from "../StatementTable";
import {
  ButtonStack,
  LoaderContainer,
  PeriodButton,
  StatementContainer,
  StyledStack,
  TypesGroupLabel,
  TypesGroupLabelWrapper,
  SubItemContainer,
  ItemContainer,
} from "./styles";
import { DebtCovenantStatementProps } from "./types";
// import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { CalendarBlank } from "phosphor-react";

export const DebtCovenantsStatement = ({
  statement,
  currentStatement,
  periodType,
  companyId,
  companyName,
  currency,
  showSelector,
  filterDataFn,
  standardizedDataFn,
  reportedDataFn,
  currentDataFn,
  currentCovDataFn,
  enableConversions,
  netTotalLeverageData,
  interestCoverData,
  cashCoverData,
  unit,
}: DebtCovenantStatementProps) => {
  const [filterOptions, setFilterOptions] = useState<FilterOptions>();

  const [data, setData] = useState<ReportLineItem[] | null>(null);
  const [currentData, setCurrentData] = useState<any[] | null>(null);
  const [reportUnit, setReportUnit] = useState<Units>();
  //FIXME type current data
  const [reportToShow, setReportToShow] = useState<StatementType>(StatementType.STANDARDIZED);
  const [selectedFinancialYear, setSelectedFinancialYear] = useState<number>(filterOptions?.financial_years[0]!);
  const [selectedPeriod, setSelectedPeriod] = useState<ReportingPeriods>(periodType);
  const [currentCovMonth, setCurrentCovMonth] = useState<number>(12);
  const [currentCovYear, setCurrentCovYear] = useState<number>(2022);
  const [activePeriodButton, setActivePeriodButton] = useState("Current");
  const [loading, setLoading] = useState(true);
  const [dates, setDates] = useState<number[]>([0, 0, 0, 0]);
  // convert dates[] from API to string for Current Debt Table
  const [originalPeriodStart, originalPeriodEnd, currentPeriodStart, currentPeriodEnd] = dates;
  const originalPeriod = formatPeriodDate(originalPeriodStart, originalPeriodEnd, periodType);
  const currentPeriod = formatPeriodDate(currentPeriodStart, currentPeriodEnd, periodType);
  // convert currentCovMonth & currentCovYear from API to string for Current Covenants Table
  const currentCovPeriodString = formatPeriodDate(currentCovMonth, currentCovYear, periodType);
  const [isPeriodSelected, setIsPeriodSelected] = useState(false);
  const [periodHasData, setPeriodHasData] = useState<PeriodHasData>();

  const fetchStandarizedFinancials = () => {
    if (selectedFinancialYear) {
      standardizedDataFn(companyId, selectedFinancialYear, selectedPeriod).then((report) => {
        setReportUnit(report.unit);
        setData(report.items);
        setPeriodHasData(report.periodTypesData);
      });
      setLoading(false);
    }
  };

  const fetchReportedFinancials = () => {
    if (selectedFinancialYear) {
      reportedDataFn(companyId, selectedFinancialYear, selectedPeriod).then((report) => {
        setReportUnit(report.unit);
        setData(report.items);
        if (report.periodTypesData?.deafultPeriod) {
          setSelectedPeriod(report.periodTypesData?.deafultPeriod as ReportingPeriods);
        }
      });
      setLoading(false);
    }
  };

  const fetchCurrentCovFinancials = () => {
    if (selectedFinancialYear) {
      setLoading(true);
      currentCovDataFn(
        companyId,
        selectedFinancialYear,
        selectedPeriod,
        [currentCovMonth, currentCovYear],
        isPeriodSelected
      ).then((report) => {
        setCurrentData(report.results); // get the covenants items
        setCurrentCovMonth(report.currentMonth); // get the current month

        setCurrentCovYear(report.financialYear); // get the current year
        if (report.periodTypesData?.deafultPeriod) {
          setSelectedPeriod(report.periodTypesData?.deafultPeriod as ReportingPeriods);
        }
      });
      setLoading(false);
    }
  };

  const fetchCurrentDebtFinancials = () => {
    if (selectedFinancialYear) {
      setLoading(true);
      currentDataFn(companyId, selectedFinancialYear, selectedPeriod, dates, isPeriodSelected).then((report) => {
        setCurrentData(report[1].data); // get the debtItems
        setDates(report[0].data); // get the original & current dates
        if (report.periodTypesData?.deafultPeriod) {
          setSelectedPeriod(report.periodTypesData?.deafultPeriod as ReportingPeriods);
        }
      });
      setLoading(false);
    }
  };

  const handleSelectChange = (event: SelectChangeEvent<unknown>) => {
    setSelectedFinancialYear(event.target.value as number);
  };

  const handlePeriodChange = (value: ReportingPeriods) => {
    setSelectedPeriod(value);
    setIsPeriodSelected(true);
  };

  const handlePeriodButtonClick = (tab: SetStateAction<string>) => {
    if (activePeriodButton !== tab) {
      setActivePeriodButton(tab);
    }
  };

  useEffect(() => {
    filterDataFn(companyId, reportToShow, selectedPeriod).then((filters) => {
      setFilterOptions(filters);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId, reportToShow]);

  useEffect(() => {
    if (filterOptions && filterOptions.financial_years && filterOptions.financial_years.length > 0) {
      setSelectedFinancialYear(filterOptions.financial_years[0]);
    }
    if (filterOptions && !filterOptions?.financial_years.length) {
      setData([]);
      setCurrentData([]);
      setLoading(false);
    }
  }, [filterOptions]);

  useEffect(() => {
    setLoading(true);
    if (reportToShow === StatementType.REPORTED) fetchReportedFinancials();
    if (reportToShow === StatementType.STANDARDIZED) fetchStandarizedFinancials();
    if (activePeriodButton === "Current" && currentStatement === DebtCovenantsStatements.CURRENT_COVENANTS)
      fetchCurrentCovFinancials();
    if (activePeriodButton === "Current" && currentStatement === DebtCovenantsStatements.CURRENT_DEBT)
      fetchCurrentDebtFinancials();

    setIsPeriodSelected(true);

    return () => {
      setData(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId, reportToShow, selectedFinancialYear, selectedPeriod, currentStatement]);

  const getReportName = () => {
    let reportType = "";
    let statementType = "";

    switch (activePeriodButton.toLowerCase()) {
      case StatementType.CURRENT_PERIOD:
        reportType = "CurrentPeriod";
        break;
      case StatementType.ALL_PERIODS:
        reportType = "AllPeriods";
        break;
      default:
        reportType = "";
    }

    switch (statement) {
      case FinancialStatements.COVENANTS:
        statementType = "Covenants";
        break;
      case FinancialStatements.DEBT_STRUCTURE:
        statementType = "DebtStructure";
        break;
    }

    return `${reportType}_${statementType}`;
  };

  return (
    <>
      {loading ? (
        <LoaderContainer>
          <LinearProgress variant="query" />
        </LoaderContainer>
      ) : (
        <StatementContainer>
          <ButtonStack direction={"row"} alignItems={"center"} width={"100%"} e2e-test-id={"statement-period-buttons"}>
            <TypesGroupLabelWrapper>
              <PeriodButton
                variant={activePeriodButton === "Current" ? "outlined" : "text"}
                onClick={() => handlePeriodButtonClick("Current")}
              >
                Current Period
              </PeriodButton>
              <PeriodButton
                variant={activePeriodButton === "All" ? "outlined" : "text"}
                sx={{ marginLeft: ".5rem" }}
                onClick={() => handlePeriodButtonClick("All")}
              >
                All Periods
              </PeriodButton>
            </TypesGroupLabelWrapper>
            <ExportToFileWrapper>
              <ExportToFile
                type={ExportToFileType.DebtStructureAndCovenants}
                debtCovenantsProps={{
                  companyName,
                  currency,
                  financialStatement: statement,
                  financialYear: selectedFinancialYear?.toString(),
                  reportingCycle: selectedPeriod,
                  statementType: reportToShow,
                  debtCovenantsCurrentCovenantPeriod: currentCovPeriodString,
                  debtCovenantsPeriod: activePeriodButton,
                  enableConversions,
                  unit: reportUnit,
                }}
                data={activePeriodButton.toLowerCase() === StatementType.CURRENT_PERIOD ? currentData : data}
                fileName={`${companyName}_${getReportName()}_${selectedFinancialYear}.xlsx`}
                fileType="xlsx"
              />
            </ExportToFileWrapper>
            {/* {activePeriodButton === "Current" && (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <FormControl variant="standard" sx={{ flexDirection: "row", marginLeft: "auto" }}>
              <DatePicker
                views={["month", "year"]}
                openTo="month"
                value={dateValue}
                onChange={handleDateChange}
                components={{ OpenPickerIcon: CalendarBlank }}
                renderInput={(params) => <BootstrapTextField size="small" InputProps={{}} {...params} />}
              />
              <DatePicker
                views={["month", "year"]}
                openTo="month"
                value={dateValue}
                onChange={handleDateChange}
                renderInput={(params) => <BootstrapTextField size="small" {...params} />}
              />
            </FormControl>
          </LocalizationProvider>
        )} */}
          </ButtonStack>
          {/* Conditional rendering for Filter group selection for All Periods or Current Period */}
          {activePeriodButton === "All" && (
            <RadioGroup defaultValue={"standardized"} name="radio-report-type">
              <Divider orientation="horizontal" sx={{ color: "#E2E2E2", borderBottomWidth: 1 }} />
              <StyledStack direction={"row"} pb={"0.375rem"} pt={"0.375rem"}>
                {showSelector ? (
                  <PeriodSelector
                    statement={statement}
                    reportingCycle={periodType}
                    value={selectedPeriod}
                    onValueChange={handlePeriodChange}
                    periodHasData={periodHasData!}
                  />
                ) : (
                  <>
                    <TypesGroupLabel
                      value={"standardized"}
                      control={<Radio />}
                      label="Show Standardized"
                      onClick={() => setReportToShow(StatementType.STANDARDIZED)}
                      e2e-test-id={"statement-standardized-radio"}
                    />
                    <TypesGroupLabel
                      value={"reported"}
                      control={<Radio />}
                      label="Show Reported"
                      onClick={() => setReportToShow(StatementType.REPORTED)}
                      e2e-test-id={"statement-reported-radio"}
                    />
                  </>
                )}

                {filterOptions && (
                  <Box ml={"auto"}>
                    <BasicSelect
                      value={selectedFinancialYear}
                      options={filterOptions.financial_years}
                      onValueChange={handleSelectChange}
                      e2e-test-id={"statement-FY-select"}
                    />
                  </Box>
                )}
              </StyledStack>
            </RadioGroup>
          )}
          {!loading && activePeriodButton === "All" && (
            <StatementTable
              companyId={companyId}
              statement={statement}
              // there are no formulas for standardized debt and we only have reported covenants
              // so we block the traceability feature for these statements
              mode={reportToShow}
              period={selectedPeriod}
              company_period={periodType}
              unit={reportUnit!}
              currency={currency}
              financialYear={selectedFinancialYear!}
              data={data!}
              enableConversions={enableConversions}
            />
          )}
          {!loading && activePeriodButton !== "All" && currentData && (
            <CurrentPeriodTable
              data={currentData}
              currentCovPeriod={currentCovPeriodString}
              currentPeriod={currentPeriod}
              originalPeriod={originalPeriod}
              currentStatement={currentStatement}
              unit={reportUnit!}
              currency={currency}
              enableConversions={enableConversions}
            />
          )}
          {!loading && (
            <ItemContainer container columnSpacing={2}>
              <SubItemContainer item xs={4}>
                {netTotalLeverageData && (
                  <Chart
                    type={ChartType.COVENANTS_NET_TOTAL_LEVERAGE}
                    title="Covenants: Net Total Leverage"
                    subtitle={`${getReportingPeriodAbbreviationLegacy(periodType)}, ${currency}, ${selectedFinancialYear}`}
                    xAxis={netTotalLeverageData?.xAxis}
                    yAxis={netTotalLeverageData?.yAxis}
                    data={netTotalLeverageData?.series}
                    unit={unit}
                  />
                )}
              </SubItemContainer>
              <SubItemContainer item xs={4}>
                {interestCoverData && (
                  <Chart
                    type={ChartType.COVENANTS_INTEREST_COVER}
                    title="Covenants: Interest Cover"
                    subtitle={`${getReportingPeriodAbbreviationLegacy(periodType)}, ${currency}, ${selectedFinancialYear}`}
                    xAxis={interestCoverData?.xAxis}
                    yAxis={interestCoverData?.yAxis}
                    data={interestCoverData?.series}
                    unit={unit}
                  />
                )}
              </SubItemContainer>
              <SubItemContainer item xs={4}>
                {cashCoverData && (
                  <Chart
                    type={ChartType.COVENANTS_CASH_COVER}
                    title="Covenants: Cash Cover"
                    subtitle={`${getReportingPeriodAbbreviationLegacy(periodType)}, ${currency}, ${selectedFinancialYear}`}
                    xAxis={cashCoverData?.xAxis}
                    yAxis={cashCoverData?.yAxis}
                    data={cashCoverData?.series}
                    unit={unit}
                  />
                )}
              </SubItemContainer>
            </ItemContainer>
          )}
        </StatementContainer>
      )}
    </>
  );
};

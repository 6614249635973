import HighchartsReact from "highcharts-react-official";
import HighCharts, { SeriesOptionsType } from "highcharts/highstock";
import { Units } from "../../types";
import { ChartWrapper } from "./styles";

export enum ChartType {
  REVENUE_PERFORMANCE = "revenue-performance",
  LEVERAGE_TREND = "leverage-trend",
  EBITDA_PERFORMANCE = "ebitda-performance",
  COVENANTS_NET_TOTAL_LEVERAGE = "Reported Covenant-NET TOTAL LEVERAGE",
  COVENANTS_INTEREST_COVER = "Reported Covenant-INTEREST COVER",
  COVENANTS_CASH_COVER = "Reported Covenant-CASH FLOW COVER",
}

export type ChartDataSeries = {
  name: string;
  data: number[];
  color?: string;
};

export type ChartAxisProps = {
  titles: string[];
  colors: string[];
};

export type ChartProps = {
  type: ChartType;
  title: string;
  subtitle: string;
  xAxis: ChartAxisProps;
  yAxis: ChartAxisProps;
  data: ChartDataSeries[];
  unit?: string;
  titleXAxis?: string;
  titleYAxis?: string;
  width?: string;
  financialYear?: string;
};

export const Chart = ({
  type,
  title,
  subtitle,
  xAxis,
  yAxis,
  data,
  unit,
  titleXAxis,
  titleYAxis,
  width,
  financialYear,
}: ChartProps) => {
  let unitSymbol = "";

  let options: Highcharts.Options = {
    chart: {
      style: {
        fontFamily: "Inter, sans-serif",
        color: "#292727",
      },
      borderRadius: 4,
      spacingLeft: 0,
      spacingTop: 8,
      marginTop: 80,
      zooming: {
        mouseWheel: { enabled: false },
      },
      animation: false,
    },

    title: {
      text: title,
      align: "left",
      x: 0,
      y: 8,
      style: {
        color: "#323030",
        fontSize: "16px",
        fontWeight: "600",
      },
    },

    subtitle: {
      text: subtitle,
      align: "left",
      x: 0,
      y: 28,
      style: {
        color: "#586463",
        fontSize: "12px",
        fontWeight: "400",
      },
    },

    legend: {
      align: "left",
      verticalAlign: "bottom",
      layout: "horizontal",
      squareSymbol: true,
      symbolRadius: 0,
      itemStyle: {
        fontSize: "12px",
      },
      margin: 30,
      padding: 0,
    },
  };

  switch (type) {
    case ChartType.REVENUE_PERFORMANCE:
      unitSymbol = unit === Units.MILLIONS ? "M" : "T";
      options = {
        ...options,
        xAxis: {
          gridLineWidth: 1,
          gridLineColor: "#E0E0E0",
          gridLineDashStyle: "Dash",
          categories: xAxis.titles,
          title: {
            text: financialYear,
            margin: 8,
            style: {
              color: "#313636",
              fontSize: "12px",
              fontWeight: "600",
            },
          },
          labels: {
            distance: 10,
            autoRotation: [-45],
            style: {
              color: "#586463",
              fontSize: "11px",
            },
          },
        },

        yAxis: {
          gridLineWidth: 1,
          gridLineColor: "#E0E0E0",
          gridLineDashStyle: "Dash",
          allowDecimals: false,
          title: {
            text: "",
          },
          labels: {
            distance: 6,
            style: {
              color: "#586463",
              fontSize: "11px",
            },
          },
        },
      };
      break;
    case ChartType.EBITDA_PERFORMANCE:
      unitSymbol = unit === Units.MILLIONS ? "M" : "T";
      options = {
        ...options,
        xAxis: {
          gridLineWidth: 1,
          gridLineColor: "#E0E0E0",
          gridLineDashStyle: "Dash",
          categories: xAxis.titles,
          title: {
            text: financialYear,
            margin: 8,
            style: {
              color: "#313636",
              fontSize: "12px",
              fontWeight: "600",
            },
          },
          labels: {
            distance: 10,
            autoRotation: [-45],
            style: {
              color: "#586463",
              fontSize: "11px",
            },
          },
        },

        yAxis: {
          gridLineWidth: 1,
          gridLineColor: "#E0E0E0",
          gridLineDashStyle: "Dash",
          allowDecimals: false,
          title: {
            text: "",
          },
          labels: {
            distance: 6,
            style: {
              color: "#586463",
              fontSize: "11px",
            },
          },
        },
      };
      break;
    case ChartType.COVENANTS_NET_TOTAL_LEVERAGE:
      unitSymbol = "x";
      options = {
        ...options,
        xAxis: {
          gridLineWidth: 0,
          gridLineColor: "#E0E0E0",
          gridLineDashStyle: "Dash",
          categories: xAxis.titles,
          labels: {
            distance: 10,
            autoRotation: [-45],
            style: {
              color: "#586463",
              fontSize: "11px",
            },
          },
        },

        yAxis: {
          gridLineWidth: 1,
          gridLineColor: "#E0E0E0",
          gridLineDashStyle: "Dash",
          allowDecimals: false,
          title: {
            text: "",
          },
          labels: {
            formatter: function () {
              return this.value + "x";
            },
            distance: 6,
            style: {
              color: "#586463",
              fontSize: "11px",
            },
          },
        },
      };
      break;
    case ChartType.COVENANTS_INTEREST_COVER:
      unitSymbol = "x";
      options = {
        ...options,
        xAxis: {
          gridLineWidth: 0,
          gridLineColor: "#E0E0E0",
          gridLineDashStyle: "Dash",
          categories: xAxis.titles,
          labels: {
            distance: 10,
            autoRotation: [-45],
            style: {
              color: "#586463",
              fontSize: "11px",
            },
          },
        },

        yAxis: {
          gridLineWidth: 1,
          gridLineColor: "#E0E0E0",
          gridLineDashStyle: "Dash",
          allowDecimals: false,
          title: {
            text: "",
          },
          labels: {
            formatter: function () {
              return this.value + "x";
            },
            distance: 6,
            style: {
              color: "#586463",
              fontSize: "11px",
            },
          },
        },
      };
      break;
    case ChartType.COVENANTS_CASH_COVER:
      unitSymbol = "x";
      options = {
        ...options,
        xAxis: {
          gridLineWidth: 0,
          gridLineColor: "#E0E0E0",
          gridLineDashStyle: "Dash",
          categories: xAxis.titles,
          labels: {
            distance: 10,
            autoRotation: [-45],
            style: {
              color: "#586463",
              fontSize: "11px",
            },
          },
        },

        yAxis: {
          gridLineWidth: 1,
          gridLineColor: "#E0E0E0",
          gridLineDashStyle: "Dash",
          allowDecimals: false,
          title: {
            text: "",
          },
          labels: {
            formatter: function () {
              return this.value + "x";
            },
            distance: 6,
            style: {
              color: "#586463",
              fontSize: "11px",
            },
          },
        },
      };
      break;
    case ChartType.LEVERAGE_TREND:
      unitSymbol = "x";
      options = {
        ...options,
        plotOptions: {
          series: {
            lineWidth: 2,
            marker: {
              symbol: "circle",
              radius: 4,
              fillColor: "#ffffff",
              lineColor: null as any,
              lineWidth: 1,
            },
          },
        },
        xAxis: {
          gridLineWidth: 1,
          gridLineColor: "#E0E0E0",
          gridLineDashStyle: "Dash",
          categories: xAxis.titles,
          title: {
            text: titleXAxis,
          },
          labels: {
            distance: 10,
            autoRotation: [-45],
            style: {
              color: "#586463",
              fontSize: "11px",
            },
          },
        },
        yAxis: [
          {
            gridLineWidth: 1,
            gridLineDashStyle: "Dash",
            allowDecimals: false,
            title: {
              margin: 40,
              text: titleYAxis,
              style: {
                fontSize: "12px",
                fontWeight: "600",
                color: "#313636",
              },
            },
            labels: {
              formatter: function () {
                return this.value + "x";
              },
              distance: -45,
              style: {
                color: "#586463",
                fontSize: "11px",
              },
            },
          },
          {
            gridLineWidth: 1,
            gridLineDashStyle: "Dash",
            allowDecimals: false,
            title: {
              text: undefined,
            },
            labels: {
              formatter: function () {
                return this.value + "x";
              },
              distance: -45,
              style: {
                color: "#586463",
                fontSize: "11px",
              },
            },
          },
        ],
      };
      break;
    default:
      break;
  }

  options = {
    ...options,
    tooltip: {
      enabled: true,
      headerFormat:
        '<span style="font-size:12px; font-weight: 600; margin-left: 2px">{point.key}</span><table style="margin-top: 6px;"',
      pointFormat: `<tr style="font-size: 12px;">
        <td><div style="width: 12px; height: 12px; background-color: {series.color}; margin-right: 5px"/></td>
        <td style="padding:1; font-weight: 400">{series.name}: </td>
        <td style="padding:4; font-weight: 400">{point.y:.2f}${unitSymbol}</td>
      </tr>`,
      footerFormat: "</table>",
      shared: true,
      useHTML: true,
    },
    series: [...(data as SeriesOptionsType[])],
  };

  return (
    <>
      <ChartWrapper width={width}>
        <HighchartsReact highcharts={HighCharts} options={options}></HighchartsReact>
      </ChartWrapper>
    </>
  );
};

import { GridColDef, GridColumnHeaderParams, GridRenderCellParams } from "@mui/x-data-grid";
import { Units } from "../../types";
import { FRCell } from "./FRCell";
import { Columns } from "../../services/financial_reporting/types";

export const getFRColumns = (currency: string, columnsData?: Columns) => {
  let unit = Units.MILLIONS;
  const FRcolumns: GridColDef[] = [
    {
      field: "datapoint_name",
      renderHeader: (params: GridColumnHeaderParams) => {
        return (
          <div e2e-test-id={"statement-currency"}>
            {currency} <span e2e-test-id={"statement-unit"}>{unit}</span>
          </div>
        );
      },
      cellClassName: "cvx-datapoint--cell",
      headerClassName: "header-title--italics",
      width: 320,
      sortable: false,
    },
    {
      field: "last_year_3",
      renderHeader: (params: GridColumnHeaderParams) => {
        return <span e2e-test-id={"last-year-3"}>{columnsData?.latestFinYears?.[0]}</span>;
      },
      headerClassName: "cvx-right--header-lowercase",
      align: "right",
      flex: 0.9,
      minWidth: 139,
      sortable: false,
      cellClassName: "table-font--large",
      renderCell: (params: GridRenderCellParams) => {
        return (
          <FRCell
            value={params.value}
            style={params.row.style}
            e2e-test-id={`value-cell-${params.row.datapoint_name}-last_year_3`}
          />
        );
      },
    },
    {
      field: "last_year_2",
      renderHeader: (params: GridColumnHeaderParams) => {
        return <span e2e-test-id={"last-year-2"}>{columnsData?.latestFinYears?.[1]}</span>;
      },
      headerClassName: "cvx-right--header-lowercase",
      align: "right",
      flex: 0.9,
      minWidth: 139,
      sortable: false,
      cellClassName: "table-font--large",
      renderCell: (params: GridRenderCellParams) => {
        return (
          <FRCell
            value={params.value}
            style={params.row.style}
            e2e-test-id={`value-cell-${params.row.datapoint_name}-last_year_2`}
          />
        );
      },
    },
    {
      field: "last_year_1",
      renderHeader: (params: GridColumnHeaderParams) => {
        return <span e2e-test-id={"last-year-1"}>{columnsData?.latestFinYears?.[2]}</span>;
      },
      headerClassName: "cvx-right--header-lowercase",
      align: "right",
      flex: 0.9,
      minWidth: 139,
      sortable: false,
      cellClassName: "table-font--large-italic",
      renderCell: (params: GridRenderCellParams) => {
        return (
          <FRCell
            value={params.value}
            style={params.row.style}
            e2e-test-id={`value-cell-${params.row.datapoint_name}-last_year_1`}
          />
        );
      },
    },
    {
      field: "ltm_latest_period",
      renderHeader: (params: GridColumnHeaderParams) => {
        return <span e2e-test-id={"ltm-latest-period"}>LTM: {columnsData?.latestLtmYear}</span>;
      },
      headerClassName: "cvx-right--header-lowercase",
      align: "right",
      flex: 0.9,
      minWidth: 139,
      sortable: false,
      cellClassName: "table-font--large-italic",
      renderCell: (params: GridRenderCellParams) => {
        return (
          <FRCell
            value={params.value}
            style={params.row.style}
            e2e-test-id={`value-cell-${params.row.datapoint_name}-ltm_latest_period`}
          />
        );
      },
    },
    {
      field: "next_year_budget_1",
      renderHeader: (params: GridColumnHeaderParams) => {
        return <span e2e-test-id={"next-year-budget-1"}>{columnsData?.latestBudgetYears?.[0]}F</span>;
      },
      headerClassName: "cvx-right--header-lowercase",
      align: "right",
      flex: 0.9,
      minWidth: 139,
      sortable: false,
      cellClassName: "table-font--large-italic",
      renderCell: (params: GridRenderCellParams) => {
        return (
          <FRCell
            value={params.value}
            style={params.row.style}
            e2e-test-id={`value-cell-${params.row.datapoint_name}-next-year-budget-1`}
          />
        );
      },
    },
    {
      field: "next_year_budget_2",
      renderHeader: (params: GridColumnHeaderParams) => {
        return <span e2e-test-id={"next-year-budget-2"}>{columnsData?.latestBudgetYears?.[1]}F</span>;
      },
      headerClassName: "cvx-right--header-lowercase",
      align: "right",
      flex: 0.9,
      minWidth: 139,
      sortable: false,
      cellClassName: "table-font--large-italic",
      renderCell: (params: GridRenderCellParams) => {
        return (
          <FRCell
            value={params.value}
            style={params.row.style}
            e2e-test-id={`value-cell-${params.row.datapoint_name}-next-year-budget-2`}
          />
        );
      },
    },
    {
      field: "next_year_budget_3",
      renderHeader: (params: GridColumnHeaderParams) => {
        return <span e2e-test-id={"next-year-budget-3"}>{columnsData?.latestBudgetYears?.[2]}F</span>;
      },
      headerClassName: "cvx-right--header-lowercase",
      align: "right",
      flex: 0.9,
      minWidth: 139,
      sortable: false,
      cellClassName: "table-font--large-italic",
      renderCell: (params: GridRenderCellParams) => {
        return (
          <FRCell
            value={params.value}
            style={params.row.style}
            e2e-test-id={`value-cell-${params.row.datapoint_name}-next-year-budget-3`}
          />
        );
      },
    },
  ];
  return FRcolumns;
};

// Static row data

export const FRStaticRows = [
  {
    id: 1,
    datapoint_name: "Revenue",
    last_year_3: 12.8,
    last_year_2: 14.0,
    last_year_1: 14.0,
    ltm_latest_period: 14.5,
    next_year_budget_1: 13.9,
    next_year_budget_2: 14,
    next_year_budget_3: 14,
  },
  {
    id: 2,
    datapoint_name: "Growth (%)",
    last_year_3: null,
    last_year_2: 9,
    last_year_1: 0,
    ltm_latest_period: 4,
    next_year_budget_1: -4,
    next_year_budget_2: 1,
    next_year_budget_3: 0,
    style: "margin",
  },
  {
    id: 3,
    datapoint_name: "Gross Profit",
    last_year_3: 8.9,
    last_year_2: 9.3,
    last_year_1: 8.5,
    ltm_latest_period: 10,
    next_year_budget_1: 7.8,
    next_year_budget_2: 8.8,
    next_year_budget_3: 9.1,
  },
  {
    id: 4,
    datapoint_name: "Margin (%)",
    last_year_3: 69,
    last_year_2: 66,
    last_year_1: 61,
    ltm_latest_period: 69,
    next_year_budget_1: 56,
    next_year_budget_2: 63,
    next_year_budget_3: 65,
    style: "margin",
  },
  {
    id: 5,
    datapoint_name: "EBITDA",
    last_year_3: -1,
    last_year_2: -2.8,
    last_year_1: -3.4,
    ltm_latest_period: -0.5,
    next_year_budget_1: -4.1,
    next_year_budget_2: 3.2,
    next_year_budget_3: 0.9,
  },
  {
    id: 6,
    datapoint_name: "Margin (%)",
    last_year_3: -8,
    last_year_2: -20,
    last_year_1: -24,
    ltm_latest_period: -3,
    next_year_budget_1: -29,
    next_year_budget_2: 23,
    next_year_budget_3: 6,
    style: "margin",
  },
  {
    id: 7,
    datapoint_name: "Adjusted EBITDA",
    last_year_3: -1,
    last_year_2: -2.8,
    last_year_1: -3.4,
    ltm_latest_period: -0.5,
    next_year_budget_1: -4.1,
    next_year_budget_2: 3.2,
    next_year_budget_3: 0.9,
  },
  {
    id: 8,
    datapoint_name: "Margin (%)",
    last_year_3: -8,
    last_year_2: -20,
    last_year_1: -24,
    ltm_latest_period: -3,
    next_year_budget_1: -29,
    next_year_budget_2: 23,
    next_year_budget_3: 6,
    style: "margin",
  },
  {
    id: 9,
    datapoint_name: "Capex",
    last_year_3: 12.7,
    last_year_2: -0.5,
    last_year_1: null,
    ltm_latest_period: null,
    next_year_budget_1: null,
    next_year_budget_2: null,
    next_year_budget_3: null,
  },
  {
    id: 10,
    datapoint_name: "Interest",
    last_year_3: -0.2,
    last_year_2: 0,
    last_year_1: null,
    ltm_latest_period: null,
    next_year_budget_1: null,
    next_year_budget_2: null,
    next_year_budget_3: null,
  },
  {
    id: 11,
    datapoint_name: "Taxes",
    last_year_3: 1.4,
    last_year_2: -1.1,
    last_year_1: null,
    ltm_latest_period: null,
    next_year_budget_1: null,
    next_year_budget_2: null,
    next_year_budget_3: null,
  },
  {
    id: 12,
    datapoint_name: "Free Cash Flow",
    last_year_3: 12.9,
    last_year_2: -4.4,
    last_year_1: null,
    ltm_latest_period: null,
    next_year_budget_1: null,
    next_year_budget_2: null,
    next_year_budget_3: null,
  },
  {
    id: 13,
    datapoint_name: "Margin (%)",
    last_year_3: 101,
    last_year_2: -31,
    last_year_1: 0,
    ltm_latest_period: 0,
    next_year_budget_1: 0,
    next_year_budget_2: 0,
    next_year_budget_3: 0,
    style: "margin",
  },
  {
    id: 14,
    datapoint_name: "KPIS",
    style: "reported-title",
  },
  {
    id: 15,
    datapoint_name: "ARR",
    last_year_3: 11.7,
    last_year_2: 13.8,
    last_year_1: 12.3,
    ltm_latest_period: 14,
    next_year_budget_1: 12.7,
    next_year_budget_2: 14,
    next_year_budget_3: 13.6,
  },
  {
    id: 16,
    datapoint_name: "Growth (%)",
    last_year_3: null,
    last_year_2: 19,
    last_year_1: -11,
    ltm_latest_period: 14,
    next_year_budget_1: -9,
    next_year_budget_2: 10,
    next_year_budget_3: -3,
    style: "margin",
  },
];

import { Box, Tabs as MUITabs } from "@mui/material";
import { a11yProps } from "../../utils/a11yProps";
import type { TabProps, TabPanelProps } from "./types";
import { useState } from "react";
import { StyledTab } from "./styles";

const TabPanel = ({ children, value, index, ...props }: TabPanelProps) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`simple-tabpanel-${index}`}
    aria-labelledby={`simple-tab-${index}`}
    {...props}
  >
    {value === index && <Box>{children}</Box>}
  </div>
);

export const AnalyzerTabs = ({ labels, content, caseVariant }: TabProps) => {
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent<Element, Event>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <MUITabs value={value} onChange={handleChange} sx={{ minHeight: "36px" }}>
          {labels?.map((labelItem: string, index: number) => (
            <StyledTab
              key={index}
              label={labelItem}
              {...a11yProps(index)}
              sx={{ textTransform: caseVariant ? caseVariant : "none" }}
            />
          ))}
        </MUITabs>
      </Box>
      {content?.map((contentItem: JSX.Element | React.ReactNode | React.ReactNode[] | string, index: number) => (
        <TabPanel key={index} value={value} index={index}>
          {contentItem}
        </TabPanel>
      ))}
    </Box>
  );
};

import { Autocomplete, Button, DocumentItem, Header, LinearProgress, Snackbar, Upload } from "@alterdomus-analytics/dna-ui";
import { Box, Stack, TextField } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { WarningCircle } from "@phosphor-icons/react";
import { useEffect, useState } from "react";
import type { Accept, FileRejection } from "react-dropzone";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import noTasks from "../../assets/images/noTasks.svg";
import { AppHeader } from "../../components/AppHeader";
import { SideBarMenu } from "../../components/SideBarMenu";
import { RootState } from "../../redux/store";
import { getAllDealNames } from "../../services/deals";
import { postFilesAndMetaData } from "../../services/upload_documents";
import { LoaderContainer } from "../CompanyPage/styles";
import {
  ButtonStack,
  Container,
  DateContainer,
  DescriptionStyled,
  ErrorMessage,
  FormStyled,
  HeaderContainer,
  IconBox,
  StyledDatePicker,
  StyledWrapper,
  TitleStyled,
  UploadContainer,
} from "./styles";
import { DocumentItemProps, IOption } from "./types";

const validFileTypes: Accept = {
  "image/*": [".jpg", ".jpeg", ".png"],
  "application/pdf": [".pdf"],
  "application/msword": [".doc"],
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"],
  "text/csv": [".csv"],
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"],
  "application/vnd.ms-excel": [".xls"],
  "text/plain": [".txt"],
};

const documentOptions: IOption[] = [
  {
    label: "",
    value: "",
    id: undefined,
  },
  {
    label: "Management Accounts",
    value: "management-accounts",
    id: 1,
  },
  {
    label: "Compliance Certificates",
    value: "compliance-certificates",
    id: 2,
  },
  {
    label: "Lender Presentation",
    value: "lender-presentation",
    id: 3,
  },
  {
    label: "Budget",
    value: "budget",
    id: 4,
  },
  {
    label: "Syndication Model",
    value: "syndication-model",
    id: 5,
  },
  {
    label: "Information Memorandum",
    value: "information-memorandum",
    id: 6,
  },
];

export const UploadPage = () => {
  const [entityOptions, setEntityOptions] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [entityValue, setEntityValue] = useState<boolean>(false);
  const [uploading, setUploading] = useState<boolean>(false);
  const [uploadMessage, setUploadMessage] = useState<string[]>([]);
  const [fileErrorMessage, setFileErrorMessage] = useState<string[]>([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [responseStatus, setResponseStatus] = useState(false);

  const storePortfolioId = useSelector<RootState, string>((state) => state.app.selectedPortfolio.data.id);
  const fileSizeLimit = 20e6; // 20MB
  const minDate = new Date("2000-01-01T00:00:00.000");
  const maxDate = new Date("2034-01-01T00:00:00.000");

  const {
    register,
    handleSubmit,
    control,
    setValue,
    reset,
    formState: { errors },
  } = useForm();
  const { fields, remove } = useFieldArray({
    control,
    name: "files", // unique name for our Field Array
  });

  // Get the entity name / companies  options
  useEffect(() => {
    setIsLoading(true);
    getAllDealNames(storePortfolioId).then((data) => {
      if (Array.isArray(data)) {
        setEntityOptions(data);
      } else {
        console.error("Error fetching deal names:", data.messages);
        setEntityOptions([]);
      }
      setIsLoading(false);
    });
  }, [storePortfolioId]);

  const handleFileUpload = (acceptedFiles: any, fileRejections: FileRejection[]) => {
    const messages = {
      required: "Upload of files is required",
      lessThan20MB: "This is larger than the 20MB limit",
      acceptedTypes: "This file type is not accepted",
    };

    const newFileRejectionsCode = fileRejections.map(({ errors }) => errors.map((e) => e.code));
    if (newFileRejectionsCode.some((codes) => codes.includes("file-invalid-type"))) {
      setFileErrorMessage([messages.acceptedTypes]);
    } else if (newFileRejectionsCode.some((codes) => codes.includes("file-too-large"))) {
      setFileErrorMessage([messages.lessThan20MB]);
    } else {
      setFileErrorMessage([messages.required]);
    }

    const newFiles = acceptedFiles.map((file: File) => ({
      file: file,
      name: file.name,
      type: file.type,
      size: file.size,
    }));

    // Check file sizes before adding to "files"
    const exceededSizeFiles = newFiles.filter((file: File) => file.size >= fileSizeLimit);

    if (exceededSizeFiles.length > 0) {
      setFileErrorMessage([messages.lessThan20MB]);
      return; // Prevent exceeded size files from being added to the array
    }

    if (!acceptedFiles.length) {
      return;
    }

    setValue("files", [...newFiles]);
    setFileErrorMessage([]);
  };

  const handleClear = (data: any) => {
    reset({});
    remove();
    setEntityValue(false);
    setFileErrorMessage([]);
  };

  const submitForm = async (data: any) => {
    setUploading(true);
    const response = await postFilesAndMetaData(data);
    setUploadMessage(response.messages);
    if (response.success) setResponseStatus(true);

    setUploading(false);
    reset({});
    remove();
    setEntityValue(false);
  };

  useEffect(() => {
    if (uploadMessage.length > 0 && !uploading) {
      setSnackbarOpen(true);
    }
  }, [uploadMessage, uploading]);

  const handleClose = () => {
    setSnackbarOpen(false);
  };

  const DisplayUploadForm = () => {
    return (
      <>
        {!entityOptions || entityOptions.length === 0 ? (
          <Stack>
            <TitleStyled variant="h5">
              There are no available entities for uploading documents,
              <br />
              in your portfolio
            </TitleStyled>
            <IconBox>
              <img src={noTasks} alt="No Table Data" />
            </IconBox>
          </Stack>
        ) : (
          <>
            <TitleStyled variant="h4">Upload documents</TitleStyled>
            <DescriptionStyled>First, tell us which company you want to upload the documents to?</DescriptionStyled>
            <Controller
              name={`entityName`}
              control={control}
              rules={{ required: "This field is required" }}
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <Autocomplete
                  name={"Entity name"}
                  onChange={(e, newValue) => {
                    onChange(newValue);
                    setEntityValue(!!newValue);
                  }}
                  value={value || null}
                  getOptionLabel={(option) => {
                    return option.label;
                  }}
                  error={error && true}
                  formHelperText={error ? error.message : ""}
                  sx={{ marginBottom: entityValue ? "-2rem" : "2.5rem" }}
                  id="entityName"
                  options={entityOptions}
                />
              )}
            />
            {entityValue && (
              <Upload
                {...register("files", {
                  required: true,
                })}
                {...fields}
              >
                <Upload.DropzoneContainer>
                  <Upload.Dropzone onDropCallback={handleFileUpload} accept={validFileTypes} />
                </Upload.DropzoneContainer>
                {fields &&
                  fields.map((file, i) => {
                    const { id, name, type } = file as any;
                    return (
                      <>
                        <DocumentItem
                          key={id}
                          fileName={name}
                          fileType={type as DocumentItemProps["fileType"]}
                          onRemoveButtonClick={() => remove(i)}
                        >
                          <Controller
                            name={`files.${i}.documentType`}
                            control={control}
                            rules={{ required: "This field is required" }}
                            render={({ field: { value, onChange }, fieldState: { error } }) => (
                              <Autocomplete
                                name={`Document Type`}
                                placeholder="Start typing any document type ..."
                                value={
                                  value
                                    ? documentOptions.find((option) => {
                                        return value === option.value;
                                      }) ?? null
                                    : null
                                }
                                getOptionLabel={(option) => {
                                  return option.label;
                                }}
                                onChange={(event: any, newValue) => {
                                  onChange(newValue ? newValue.value : null);
                                }}
                                error={error && true}
                                formHelperText={error ? error.message : ""}
                                id="documentType"
                                options={documentOptions}
                              />
                            )}
                          />
                          <Box
                            sx={{
                              display: "grid",
                              gridTemplateColumns: "1fr 1fr",
                              width: "100%",
                              gap: "8px",
                              marginBottom: "16px",
                              marginTop: "16px",
                            }}
                          >
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DateContainer>
                                <Controller
                                  name={`files.${i}.financialYear`}
                                  control={control}
                                  rules={{ required: "This field is required" }}
                                  defaultValue={null}
                                  render={({ field: { value, onChange }, fieldState: { error } }) => (
                                    <StyledDatePicker
                                      label="Financial Year"
                                      openTo="year"
                                      views={["year"]}
                                      minDate={minDate}
                                      maxDate={maxDate}
                                      inputFormat="YYYY"
                                      value={value}
                                      onChange={onChange}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          name={`files.${i}.financialYear`}
                                          error={error && true}
                                          helperText={error ? error.message : ""}
                                        />
                                      )}
                                    />
                                  )}
                                />
                              </DateContainer>
                              <DateContainer>
                                <Controller
                                  name={`files.${i}.financialPeriod`}
                                  control={control}
                                  rules={{ required: "This field is required" }}
                                  defaultValue={null}
                                  render={({ field: { value, onChange }, fieldState: { error } }) => (
                                    <StyledDatePicker
                                      label="Financial Period"
                                      openTo="month"
                                      views={["month"]}
                                      minDate={minDate}
                                      maxDate={maxDate}
                                      inputFormat="MMM"
                                      value={value}
                                      onChange={onChange}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          name={`files.${i}.financialPeriod`}
                                          error={error && true}
                                          helperText={error ? error.message : ""}
                                        />
                                      )}
                                    />
                                  )}
                                />
                              </DateContainer>
                            </LocalizationProvider>
                          </Box>
                        </DocumentItem>
                      </>
                    );
                  })}
              </Upload>
            )}
            {fileErrorMessage.length > 0 && (
              <Stack display={"flex"} flexDirection={"row"} alignItems={"flex-start"} color={"#9F372F"}>
                <WarningCircle size={18} />
                {fileErrorMessage.map((errorMessage, i) => (
                  <ErrorMessage key={i}>{errorMessage}</ErrorMessage>
                ))}
              </Stack>
            )}
            {errors.files && (
              <Stack display={"flex"} flexDirection={"row"} alignContent={"center"} color={"#9F372F"}>
                <WarningCircle size={18} />
                <ErrorMessage>{errors.files && "Upload of files is required"}</ErrorMessage>
              </Stack>
            )}
          </>
        )}
      </>
    );
  };

  return (
    <>
      <AppHeader />
      <StyledWrapper>
        <SideBarMenu />
        <Container item xs={12}>
          <FormStyled onSubmit={handleSubmit(submitForm)} encType="multipart/form-data">
            <HeaderContainer isSticky={true}>
              <Header
                title="Upload Documents"
                width="100%"
                children={
                  <ButtonStack flexDirection={"row"}>
                    <Button label="Cancel" variantType="tertiary" onClick={handleClear} />
                    <Button label="Submit" variantType="primary" type="submit" />
                  </ButtonStack>
                }
              />
            </HeaderContainer>
            {isLoading && (
              <LoaderContainer>
                <LinearProgress variant="query" />
              </LoaderContainer>
            )}
            <Snackbar
              open={snackbarOpen}
              message={uploadMessage[0]}
              severity={responseStatus ? "success" : "warning"}
              handleClose={handleClose}
            />
            <UploadContainer>
              {!uploading && DisplayUploadForm()}
              {uploading && <TitleStyled variant="h4">Uploading...</TitleStyled>}
            </UploadContainer>
          </FormStyled>
        </Container>
      </StyledWrapper>
    </>
  );
};

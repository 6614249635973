import { Tab, styled } from "@mui/material";

export const StyledTab = styled(Tab)`
  font-size: 14px;
  align-items: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  padding: 10px 12px;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  :first-of-type {
    margin-left: 0;
  }
`;

import { Box, CardActions, CardContent, CardHeader, Collapse, Grid, Link, Stack, Typography } from "@mui/material";
import { Modal, LinearProgress } from "@alterdomus-analytics/dna-ui";
import { SetStateAction, useState } from "react";
import { CommentCard, ModalButton, EditorWrapper, StyledTertiaryButton, OutlinedButton, PrimaryButton } from "./styles";
import ReactQuill from "react-quill";
import { CardProps } from "./types";
import parse from "html-react-parser";
import DOMPurify from "dompurify";
import { removeHtml } from "../../utils/auxiliary";
import { config } from "../Editor/editor.config";
import { updateDealDetails } from "../../services/deals";
import { useDispatch } from "react-redux";
import { setCompanyField } from "../../redux/reducers/companyReducer";

type UpdateError = {
  message: string;
  blocksRetry?: boolean;
};

export const Commentary = ({
  title = "",
  subtitle,
  titleHeadingLevel = "h4",
  date,
  text,
  children,
  headerSlot,
  modalHeaderSlot,
  modalButton = true,
  cutoff,
  maxWidth,
  activeTab,
  styles,
  handleViewCommentaryClick,
  closeModal,
  companyId,
}: CardProps) => {
  const sanitizedText = text ? DOMPurify.sanitize(text) : "";

  const [showModal, setShowModal] = useState(false);
  const [modalInEditMode, setModalEditMode] = useState(false);
  const [editValue, setEditValue] = useState(sanitizedText);
  const [readValue, setReadValue] = useState(sanitizedText);
  const [validationMessage, setValidationMessage] = useState("");
  const [validationError, setValidationError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setEditValue(readValue);
    setValidationError(false);
    setValidationMessage("");
    setModalEditMode(false);
    setShowModal(false);
  };

  const handleSaveEdit = async () => {
    if (validationError) return;
    setIsLoading(true);

    const response = await updateDealDetails(companyId, title, editValue);
    response.errors = response.messages.map((m: UpdateError) => {
      return { message: m };
    });

    if (response.success) {
      setReadValue(editValue);
      setModalEditMode(false);
      setValidationError(false);
      dispatch(setCompanyField({ [title]: editValue }));
    } else {
      setValidationError(true);
      setValidationMessage(response.messages.join("\n"));
    }

    setIsLoading(false);
  };

  const handleEditorChange = (value: SetStateAction<string>) => {
    const maxCharacterMap: Record<string, number> = {
      "Transaction Background": 8000,
      "Business Description": 8000,
    };
    const maxCharacters = maxCharacterMap[title];

    // Extract the string from SetStateAction
    const stringValue = typeof value === "string" ? value : value(editValue);

    // Sanitize the value to remove HTML characters so the calculation of chars is precise
    const sanitizedValue = removeHtml(stringValue);
    const remainingCharacters = maxCharacters - sanitizedValue.length;

    if (value.length > maxCharacters) {
      setValidationError(true);
      setValidationMessage(`${title} cannot exceed ${maxCharacters} characters`);
    } else {
      setValidationError(false);
      setValidationMessage(`You have ${remainingCharacters} characters left.`);
    }
    setEditValue(value);
  };

  const handleCancel = () => {
    setEditValue(readValue);
    setValidationError(false);
    setValidationMessage("");
    setModalEditMode(false);
  };

  const modalContent = !modalInEditMode ? (
    <>
      <div>{parse(readValue)}</div>
      <OutlinedButton label="Edit" onClick={() => setModalEditMode(true)}></OutlinedButton>
    </>
  ) : (
    <>
      <EditorWrapper>
        {isLoading && <LinearProgress variant="query" />}
        {!isLoading && <ReactQuill {...config} theme="snow" value={editValue} onChange={handleEditorChange} />}
      </EditorWrapper>
      {validationMessage.length > 0 && (
        <Typography variant="body2" color={validationError ? "#9F372F" : ""} component="div" mt={"0.5rem"}>
          {validationMessage}
        </Typography>
      )}
      <Stack direction="row" spacing={2} sx={{ marginTop: "24px" }}>
        <PrimaryButton label="Save" onClick={handleSaveEdit} disabled={validationError} />
        <StyledTertiaryButton label="Cancel" variantType="tertiary" onClick={handleCancel} />
      </Stack>
    </>
  );

  const handleOnClick = () => {
    //closeModal is only true from the commentaryTab component
    //when the commetary tab is the one active.
    if (closeModal) {
      setShowModal(false);
    } else if (handleViewCommentaryClick) {
      handleViewCommentaryClick();
    }
  };

  const displayButton = sanitizedText.length < 1074 && activeTab ? false : true;
  return (
    <>
      <CommentCard variant="outlined" sx={{ maxWidth: "100%", ...styles }}>
        <Grid container columnSpacing={"1.5rem"} sx={{ maxWidth: "100%" }}>
          <Grid item xs={!activeTab ? 12 : 3}>
            {!activeTab ? (
              <CardHeader
                title={
                  <Stack direction="row" sx={{ alignItems: "center" }}>
                    <Typography variant={titleHeadingLevel} component="div" mr={1.5} e2e-test-id={`title-${title}`}>
                      {title}
                    </Typography>
                    <Typography variant="body2" component="div" e2e-test-id={`subtitle-${date}`}>
                      {subtitle} {date}
                    </Typography>
                    {headerSlot && (
                      <Stack direction="row" ml={"auto"} e2e-test-id={`commentary-link-${title}`}>
                        {headerSlot}
                      </Stack>
                    )}
                  </Stack>
                }
              />
            ) : (
              <CardHeader
                title={
                  <Stack>
                    <Typography variant="body2" component="div" e2e-test-id={`acomment-subtitle-panel${1}`}>
                      <Box sx={{ fontWeight: "bold" }}>Reporting Period</Box>
                    </Typography>
                    <Typography variant="body2" component="div" e2e-test-id={`acomment-subtitle-panel${2}`}>
                      {subtitle}
                    </Typography>
                  </Stack>
                }
              />
            )}
          </Grid>
          <Grid item xs={!activeTab ? 12 : 9}>
            <Grid container>
              <Grid item xs={12} sm={12}>
                <CardContent>
                  <Collapse in={false} collapsedSize={cutoff}>
                    <Typography variant="body1" e2e-test-id={`content-${title}`}>
                      {parse(sanitizedText)}
                    </Typography>
                    {children}
                  </Collapse>
                </CardContent>
              </Grid>
              <Grid item xs={12} sm={12}>
                <CardActions>
                  {modalButton && displayButton && (
                    <ModalButton
                      label="Read more"
                      variantType="tertiary"
                      onClick={handleOpenModal}
                      e2e-test-id={`button-${title}`}
                    />
                  )}
                </CardActions>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CommentCard>
      {modalHeaderSlot ? (
        <Modal
          open={showModal}
          onClose={handleCloseModal}
          title={title}
          subtitle={subtitle}
          headerSlot={
            <Link
              underline="hover"
              onClick={() => handleOnClick()}
              sx={{
                fontSize: "inherit",
                marginTop: "2.375rem",
                paddingTop: "8px",
                cursor: "pointer",
              }}
            >
              {"View commentary archive"}
            </Link>
          }
          children={parse(readValue)}
        />
      ) : (
        <Modal open={showModal} onClose={handleCloseModal} title={title} subtitle={subtitle} children={modalContent} />
      )}
    </>
  );
};

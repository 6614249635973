import { ReportLineItem } from "../types";

export const getFiles = (data: ReportLineItem[], index: number) => {
  const files: Map<string, string> = new Map();

  for (const item of data) {
    if (!item.values) continue;

    if (!item.values[index]?.file_upload_uri) {
      continue;
    }
    files.set(item.values[index].file_upload_name as string, item.values[index].file_upload_uri as string);
  }

  return Array.from(files.entries());
};

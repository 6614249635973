import { MultiSelectItemType } from "../components/MultiSelect";

export type AxiosResponseObject<T> = {
  data: T;
};

export type MicrosoftGraphResponseObject<T> = {
  value: T;
};

export type ApiError = {
  error: string;
  message: string;
  statusCode: number;
};

export type AxiosErrorResponseObject = {
  response: { data?: ApiError };
};

// Analyzer
export enum AnalyzerTables {
  OVERVIEW = "OVERVIEW",
  REVENUE_EBITDA = "REVENUE_EBITDA",
  COVENANTS = "COVENANTS",
}

export enum RevEbitdaViews {
  AMOUNT = "AMOUNT",
  PERCENTAGE = "PERCENTAGE",
}
//TODO Decide whether this is still needed or use specific interfaces below
export interface IRow {
  id: number;
  company_name: string;
  company_trade_name: string;
  company_id: number;
  period: string;
  periodType: ReportingPeriods;
  sponsors: string[];
  sector: string;
  currency: string;
  country: string;
  par_value: number;
  all_in_rate: string;
  seniority: string[];
  internal_rating: string;
  funds: string[];
  actual: number | string;
  enableConversions: boolean;
}

export interface AnalyzerRow {
  [key: string]: string[] | string | number | boolean;
}

export type DealOverview = {
  dealId: number;
  portfolioId: string;
  companyId: number;
  companyName: string;
  companyTradeName: string;
  period: string;
  reportingCycle: ReportingPeriods;
  sponsors: string[];
  sector: string;
  currency: string;
  country: string;
  parValue: number;
  allInRate: string;
  seniority: string[];
  internalRating: string;
  funds: string[];
  revenueActual: number;
  revenueVsBudget: number;
  revenueVsLastYear: number;
  ebitdaActual: number;
  ebitdaVsBudget: number;
  ebitdaVsLastYear: number;
  openingLeverage: number;
  leverage: number;
  enableConversions: boolean;
};

export type DealRevenueEbitda = {
  dealId: number;
  companyId: number;
  companyName: string;
  companyTradeName: string;
  period: string;
  reportingCycle: ReportingPeriods;
  currency: string;
  revenueActual: string;
  revenueVsBudget: string;
  revenueVsLastYear: string;
  ebitdaActual: string;
  ebitdaVsBudget: string;
  ebitdaVsLastYear: string;
  openingLeverage: string;
  leverage: string;
};

export type DealCovenant = {
  dealId: number;
  portfolioId: number;
  companyId: number;
  companyName: string;
  companyTradeName: string;
  period: string;
  reportingCycle: ReportingPeriods;
  currency: string;
  openingLeverage: number;
  leverage: number;
  leverageCovenantTest: number;
  leverageHeadroom: number;
  interestCurrent: number;
  interestCovenantTest: number;
  interestHeadroom: number;
  cashCurrent: number;
  cashCovenantTest: number;
  cashHeadroom: number;
};

export interface Company {
  deal_name: string;
  company_trade_name: string;
  currency: string;
  country: string;
  sector: string;
  sponsors: string[];
  deal_team: string;
  co_investors: string[];
  transaction_date: string;
  reporting_cycle: "m" | "q";
  reporting_unit: string;
  financial_year_end: string;
  management_commentary?: ManagementCommentary;
  description: string;
  transaction_background: string;
  current_period: string;
  cellid?: number;
  enable_conversions: boolean;
  internal_rating: number;
  orgId: string;
  fin_model_uri: string;
  reports: string[];
}

export enum ReportingPeriods {
  Monthly = "monthly",
  Quarterly = "quarterly",
  YTD = "ytd",
  LTM = "ltm",
}

export enum Units {
  MILLIONS = "Millions",
  THOUSANDS = "Thousands",
}

export enum ReportSessionItemStyles {
  MARGIN = "margin",
  RATIO = "ratio",
  STANDARD_SUBHEADER = "standard-subheader",
  STANDARD_TOTAL = "standard-total",
  STANDARD_SUBTOTAL = "standard-subtotal",
  STANDARD_TITLE = "standard-title",
  REPORTED_TITLE = "reported-title",
  REPORTED_TOTAL = "reported-total",
  REPORTED_SUBTOTAL = "reported-subtotal",
  PERIOD_TITLE = "period-title",
}

export interface ManagementCommentary {
  id: number;
  deal_id: number;
  user_id: string;
  reporting_period: string;
  text: string;
  timestamp: string;
}

export interface ManagementCommentaryByYear {
  latestYear?: number;
  comments: ManagementCommentary[];
  availableYears?: number[];
}

export interface AnalystComment {
  id: number;
  deal_id: number;
  user_id: string;
  user_name: string;
  text: string;
  timestamp: string;
  reporting_period: string;
}

export interface AnalystCommentsByYear {
  latestYear: number;
  comments: AnalystComment[];
}

export interface AnalyzerFilters {
  portfolios: MultiSelectItemType;
  funds: MultiSelectItemType[];
  sectors: MultiSelectItemType[];
  countries: MultiSelectItemType[];
}

export interface FilterOptions {
  financial_years: number[];
}

export type Portfolio = {
  id: string;
  customer_id: number;
  portfolio_type: string;
  portfolio_name: string;
};

export type Fund = {
  id: string;
  customerId: number;
  name: string;
  description: string;
};

export type Sector = {
  id: number;
  customerId: number;
  name: string;
  description: string;
};

export type Country = {
  id: number;
  customerId: number;
  name: string;
  description: string;
};

export type PageMetaDataType = {
  page: number;
  take: number;
  itemCount: number;
  pageCount: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
};

export type ApiResponseType<T> = {
  data: T[];
  meta: PageMetaDataType;
};

// Financial Statements
export interface ReportSection {
  id: number;
  company_id: number;
  name: string;
  universal_line_item: string;
  order: number;
  report_id?: number;
  style?: string | null;
  items?: ReportLineItem[];
}

export interface ReportLineItem {
  id: number;
  name: string;
  company_id: number;
  universal_line_item: string;
  order: number;
  section_id?: number;
  formula_string?: string;
  formula_is_mapping?: boolean;
  style?: string | null;
  values?: ReportLineItemValue[];
}

export interface ReportLineItemValue {
  id: number;
  company_id: number;
  universal_line_item_id: string;
  value: number;
  reported_month: number;
  reported_quarter: number;
  financial_year: number;
  reported_day?: Date;
  reported_year?: number;
  file_upload_uuid?: string;
  file_upload_date?: Date;
  file_upload_name?: string;
  file_upload_uri?: string;
  processing_date?: Date;
  insert_timestamp?: Date;
  formula_statement?: string;
  formula_string?: string;
  formula_is_mapping?: boolean;
  pdf_left_coordinate?: number;
  pdf_right_coordinate?: number;
  pdf_top_coordinate?: number;
  pdf_bottom_coordinate?: number;
  pdf_page_number?: number;
}

export interface FinancialStatementType {
  id: number;
  company_id: number;
  report_type: number;
  unit: Units;
  sections: ReportSection[];
  periodTypesData?: PeriodHasData;
}
//FIXME replace with changed FinancialStatementType when all statement responses are aligned
export interface SimplifiedFinancialStatement {
  id: number;
  company_id: number;
  report_type: number;
  unit: Units;
  items: ReportLineItem[];
  periodTypesData?: PeriodHasData;
}

export enum StatementType {
  STANDARDIZED = "standardized",
  REPORTED = "reported",
  BUDGET = "budget",
  CURRENT_PERIOD = "current",
  ALL_PERIODS = "all",
}

export enum FinancialStatements {
  BALANCE_SHEET = "BALANCE_SHEET",
  CASH_FLOW = "CASH_FLOW",
  INCOME_STATEMENT = "INCOME_STATEMENT",
  KPI_STATEMENT = "KPI_STATEMENT",
  DEBT_STRUCTURE = "DEBT_STRUCTURE",
  COVENANTS = "COVENANTS",
}

export enum DebtCovenantsStatements {
  CURRENT_COVENANTS = "CURRENT_COVENANTS",
  CURRENT_DEBT = "CURRENT_DEBT",
}

export interface TraceabilityDetails {
  id: number;
  data_point: string;
  universal_line_item: string;
  value: number;
  formula: string;
  params: TraceabilityParam[];
  style?: string;
}

export interface TraceabilityParam {
  formula: string;
  formula_is_mapping: any;
  index: number;
  universal_line_item: string;
  name: string;
  value: number;
  is_mapping: boolean;
  operator?: string;
  style?: string;
  formula_statement: string;
}

// Debt & Covenants Current Period Statements
export interface Report {
  id: number;
  company_id: number;
  report_type: number;
  unit: Units;
  sections: Section[];
}

export interface Section {
  id: number;
  name: string;
  universal_line_item: string;
  order: number;
  style: null | string;
  report_id: number;
  items: Item[];
}

export interface ItemOccurrences {
  name: string;
  occurrences: Item[];
}

export interface Item {
  id: number;
  section_id: number;
  name: string;
  universal_line_item: string;
  formula_string: null | string;
  order: number;
  style: null | string;
  values: any[];
}

export interface ConvertedItem {
  id: number;
  datapoint_name: string;
  netTotalLeverage: number;
  netSeniorLeverage: number;
  interestCover: number;
  cashFlowCover: number;
  style?: string;
}

export interface IFormInput {
  entityName: { name: string; id: string };
  documentTypes: string[];
  financialYears: string[];
  financialPeriods: string[];
  uploadCvDocuments: { name: string; type: string }[];
}
export type Tool = {
  customerId: number;
  name: string;
  url: string;
};

export type PortfolioAnalyzerFilters = {
  portfolios: Portfolio[];
  funds: Fund[];
  sectors: Sector[];
  countries: Country[];
  parValue: PerformanceFilterOption[];
  leverage: PerformanceFilterOption[];
  revenueVsBudget: PerformanceFilterOption[];
  revenueVsLastYear: PerformanceFilterOption[];
};

export type PeriodHasData = {
  deafultPeriod: string;
  hasLtm: boolean;
  hasMonthly: boolean;
  hasQuarterly: boolean;
  hasYtd: boolean;
};

export type PerformanceFilterOption = {
  value: number;
  label: string;
};

export enum DataFormatTypes {
  none = "",
  ratio = "x",
  margin = "%",
}

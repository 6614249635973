import { AccountInfo, AuthenticationResult } from "@azure/msal-browser";
import { deleteAuthData, setAuthData } from "../../redux/reducers/authReducer";
import { store } from "../../redux/store";
import { apiTokenConfig, msalInstance } from "./config";

export const auth = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await logIn();
      await setAuthDataToState(response);
      (window as any).heap.addUserProperties({"email": response.account?.username});
      window.location.href = process.env.REACT_APP_MSAL_REDIRECT_URI || "/analyzer";
      resolve(<></>);
    } catch (error) {
      reject(error);
    }
  });
};

export const getAllAccounts = async (): Promise<AccountInfo[]> => {
  return new Promise((resolve, reject) => {
    const accounts = msalInstance.getAllAccounts();
    resolve(accounts);
  });
};

const logIn = async (): Promise<AuthenticationResult> => {
  await msalInstance["browserStorage"].clear();
  return new Promise(async (resolve, reject) => {
    try {
      const accounts = await getAllAccounts();
      if (accounts.length === 0) {
        try {
          await msalInstance.loginPopup();
          // Get access token after authentication is complete
          try {
            const tokenResponse = await getTokenResponse();
            resolve(tokenResponse);
          } catch (error) {
            reject(error);
          }
        } catch (error) {
          reject(error);
        }
      }
    } catch (error) {
      reject(error);
    }
  });
};

export const getTokenResponse = async () => {
  const accounts = await getAllAccounts();

  try {
    const tokenResponse = await msalInstance.acquireTokenSilent({
      ...apiTokenConfig,
      account: accounts[0],
    });

    return tokenResponse;
  } catch (error) {
    throw error;
  }
};

export const logOut = async () => {
  try {
    await msalInstance.logoutPopup();
    store.dispatch(deleteAuthData());
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const handleSessionExpired = async () => {
  const accounts = await getAllAccounts();

  window.location.href = "/session-timeout";

  store.dispatch(deleteAuthData());
  msalInstance.logoutRedirect({ account: accounts[0] });
};

export const handleError = async () => {
  window.location.href = "/error";

  const accounts = await getAllAccounts();
  store.dispatch(deleteAuthData());
  msalInstance.logoutRedirect({ account: accounts[0] });
};

export const setAuthDataToState = async (data: AuthenticationResult) => {
  // adding user data in redux state
  store.dispatch(
    setAuthData({
      id: data.account?.localAccountId as string,
      name: data.account?.name,
      preferred_username: data.account?.username,
      email: data.account?.username as string,
      roles: [],
      auth: {
        account: data.account,
        idToken: data.idToken,
        accessToken: data.accessToken,
        expiresOn: data.expiresOn as Date,
      },
    })
  );
};

/**
 * Login silent if a user has fetched token with msal prior
 */
export const loginSilent = async () => {
  const accounts = await getAllAccounts();

  if (accounts.length === 0) {
    console.debug("🚀 ~ loginSilent ~ No accounts found. Cannot log in silently.");
    return;
  }

  try {
    const response = await msalInstance.acquireTokenSilent({ ...apiTokenConfig, account: accounts[0] });
    await setAuthDataToState(response).then(() => {
      window.location.href = process.env.REACT_APP_MSAL_REDIRECT_URI || "/analyzer";
    });
  } catch (error) {
    console.log(error);
  }
};

/**
 * Login redirect
 */
export const loginRedirect = async () => {
  const accounts = await getAllAccounts();

  if (accounts.length > 0) {
    // If the user has signed in already, redirect them silently
    loginSilent();
  } else {
    // Make sure a user isn't returning from auth with a token
    await msalInstance
      .handleRedirectPromise()
      .then(async (tokenResponse) => {
        if (tokenResponse) {
          try {
            // All things are good, set the auth data to state and redirect them to the analyzer
            await setAuthDataToState(tokenResponse).then(() => {
              window.location.href = process.env.REACT_APP_MSAL_REDIRECT_URI || "/analyzer";
            });
          } catch (error) {
            console.error("🚀 ~ handleRedirectPromise().then ~ error:", error);
          }
        }
      })
      .catch((error) => {
        console.error("🚀 ~ handleRedirectPromise() ~ error:", error);
      });

    // No account, and the handleRedirectPromise didn't return anything useful, so we want to redirect them to login
    msalInstance.loginRedirect({
      ...apiTokenConfig,
      // Return them to the ?login page after authentication so we can make sure to handle their token properly
      redirectUri: `${process.env.REACT_APP_BASE_URL}?login`,
    });
  }
};

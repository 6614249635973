import styled from "@emotion/styled";
import { Box, Input } from "@mui/material";
import { Button, Datepicker } from "@alterdomus-analytics/dna-ui";

export const DealDetailItem = styled(Box)`
  padding: 1rem 1rem;
  border-bottom: 1px solid #e2e2e2;

  .MuiTypography-body1 {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #292727;
  }
  .MuiTypography-h6 {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #586463;
  }
  .MuiTypography-body2 {
    font-size: 14px;
  }
`;

export const StyledTertiaryButton = styled(Button)`
  padding: 0.5rem 0.75rem;
  color: #1a50dd;
  border-radius: 100px;
  border: none;
  background-color: #fff;
  text-transform: none;
  min-width: 82px;
  margin-top: -0.5rem;
  margin-right: -0.25rem;
`;

export const StyledSecondaryButton = styled(Button)({
  "&": {
    padding: "5px 0px",
    color: "#1a50dd",
    borderRadius: "50%",
    border: "1px solid #1a50dd",
    backgroundColor: "#fff",
    textTransform: "none",
    minWidth: "32px",
    minHeight: "32px",
  },
  "&:hover": {
    color: "#5F5F5F",
  },
});

export const StyledTextField = styled(Input)({
  "&": {
    flex: "1 1 0",
    fontSize: "14px",
    padding: 0,
    border: "none",
    borderBottom: "1px solid",
    borderWidth: "1px",
    marginTop: "4px",
  },
  "&::before": {
    display: "none",
  },
  "&::after": {
    display: "none",
  },
  "&:hover": {
    borderColor: "#5F5F5F",
  },
});

export const StyledDateField = styled(Datepicker)({
  "&": {
    flex: "1 1 0",
    padding: 0,
    border: "none",
    borderBottom: "1px solid",
    borderWidth: "1px",
    marginTop: "8px",
  },
  "& > .MuiOutlinedInput-root": {
    maxHeight: "32px",
  },
  "& > .MuiOutlinedInput-root > .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
  "& > .MuiOutlinedInput-root >.MuiOutlinedInput-input": {
    fontSize: "14px",
    padding: 0,
    paddingBottom: "3px",
  },
  "& > .MuiOutlinedInput-root > .MuiInputAdornment-root > .MuiButtonBase-root": {
    color: "#1a50dd",
    marginTop: "-5px",
  },
  "&:hover": {
    borderColor: "#5F5F5F",
  },
});

export const DateContainer = styled(Box)({
  "& > div": {
    padding: "0",
  },
});

export const LoaderContainer = styled(Box)`
  height: 100%;
  padding-top: 2rem;
`;
